import React from "react";
import { useSelector } from "react-redux";

import { selectSelectedPlan } from "store/slices/plan";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PlanFreeInformationSection from "../PlanFreeInformationSection";

import styles from "./index.module.scss";

const PlanInformationSection: React.FC = () => {
  const selectedPlan = useSelector(selectSelectedPlan);

  return (
    <PungoSectionCard title={""}>
      <div className={styles.container}>
        {selectedPlan === "free" ? <PlanFreeInformationSection /> : null}
      </div>
    </PungoSectionCard>
  );
};

export default PlanInformationSection;
