import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const PlanFreeInformationSection: React.FC = () => {
  const { t } = useTranslation();

  const getFreePlanInformationText = () => (
    <div className={styles.text}>
      <div className={styles.note}>
        <Trans>{t("DemoPage.PlanInformationSection.note1")}</Trans>
      </div>
      <div className={styles.division}></div>
      <div className={styles.note}>
        <Trans>{t("DemoPage.PlanInformationSection.note2")}</Trans>
      </div>
    </div>
  );

  return (
      <div className={styles.container}>
        {getFreePlanInformationText()}
      </div>
  );
};

export default PlanFreeInformationSection;
