import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff, CancelRounded } from "@material-ui/icons";

import PungoButton from "pungo-ui/PungoButton";

import styles from "./index.module.scss";

interface ISelectableOptions {
  value: number | string;
  label: number | string;
}

interface PungoInputProps {
  autoComplete?: string;
  name: string;
  helperText?: string | undefined;
  type?: "password" | "number" | "text" | "select" | "date";
  onChange: (value: any) => void;
  onFocus?: (value: any) => void;
  onErase?: () => void;
  value: string | number;
  options?: ISelectableOptions[];
  disabled?: boolean;
  size?: "small" | "medium";
  canBeErased?: boolean;
  showError?: boolean;
  className?: string;
}

const PungoInput: React.FC<PungoInputProps> = (props) => {
  const {
    autoComplete,
    name,
    helperText,
    type = "text",
    size = "medium",
    onChange,
    onFocus,
    onErase,
    value,
    options,
    disabled,
    canBeErased = false,
    showError = false,
    className
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const isDateInput = type === "date";

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className={styles.container}>
      {type !== "select" ? (
        <TextField
          autoComplete={autoComplete ? autoComplete : "one-time-code"}
          label={name}
          value={value}
          size={size}
          disabled={disabled}
          className={className}
          inputProps={
            isDateInput
              ? {
                shrink: "true",
              }
              : undefined
          }
          InputLabelProps={
            isDateInput
              ? {
                shrink: true,
              }
              : undefined
          }
          onKeyDown={isDateInput ? (e) => e.preventDefault() : undefined}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          variant="outlined"
          error={showError}
          helperText={helperText}
          onChange={(event) => onChange(event.target.value)}
          onFocus={onFocus ? (event) => onFocus(event) : undefined}
          InputProps={{
            endAdornment:
              type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    className={styles.icon}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : (type === "text" || type === "date") &&
              value &&
              canBeErased ? (
                <PungoButton
                  onClick={onErase ? () => onErase() : () => onChange("")}
                  type="icon"
                  icon={
                    <CancelRounded
                      fontSize="small"
                      className={styles.cancelButton}
                    />
                  }
                />
              ) : undefined,
          }}
        />
      ) : (
        <FormControl fullWidth disabled={disabled}>
          <InputLabel>{name}</InputLabel>
          <Select
            label={name}
            value={String(value)}
            size={size}
            onChange={(event: SelectChangeEvent) => {
              onChange(event.target.value as string);
            }}
          >
            {options?.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default PungoInput;
