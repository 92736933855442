import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface State {
  ongoingCalls: string[];
}

const initialState: State = {
  ongoingCalls: [],
};
const usersSlice = createSlice({
  name: "apiData",
  initialState,
  reducers: {
    addAPIcall: (state, action: PayloadAction<string>) => {
      state.ongoingCalls.push(action.payload);
    },
    completeAPIcall: (state, action: PayloadAction<string>) => {
      const index = state.ongoingCalls.findIndex((el) => el === action.payload);
      if (index >= 0) {
        state.ongoingCalls.splice(index, index + 1);
      }
    },
    clearAPIcalls: (state) => {
      state.ongoingCalls = [];
    },
  },
});
export const { addAPIcall, completeAPIcall, clearAPIcalls } =
  usersSlice.actions;

export default usersSlice.reducer;

export const selectOngoingAPICalls = (state: RootState): string[] =>
  state.api.ongoingCalls;
