import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import {
  createModel,
  deleteModel,
  editModel,
  fetchModels,
} from "store/actions/modelActions";
import { selectModels, selectTotalModelsPages } from "store/slices/model";

import styles from "./index.module.scss";

const AdminModelsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const modelsData = useSelector(selectModels);
  const totalModelsPages = useSelector(selectTotalModelsPages);

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialNameFilter = "";
  const initialDescriptionFilter = "";
  const initialUrlFilter = "";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [urlFilter, setUrlFilter] = useState(initialUrlFilter);
  const [descriptionFilter, setDescriptionFilter] = useState(
    initialDescriptionFilter
  );

  const [selectedModelId, setSelectedModelId] = useState<number | undefined>();
  const [nameInModal, setNameInModal] = useState("");
  const [descriptionInModal, setDescriptionInModal] = useState("");
  const [urlInModal, setUrlInModal] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [filteredModelCallTimeoutId, setFilteredModelCallTimeoutId] = useState(0);

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const paramsForModelTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(nameFilter && { name: nameFilter }),
    ...(descriptionFilter && { description: descriptionFilter }),
    ...(urlFilter && { url: urlFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  /**
   * Model filter effects
   */
  useEffect(() => {
    if(!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterModels(0);
        allFiltersChange.current = false;
      } else {
        if (filteredModelCallTimeoutId) {
          clearTimeout(filteredModelCallTimeoutId);
        }
        setFilteredModelCallTimeoutId(setTimeout(handleFilterModels, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    nameFilter,
    descriptionFilter,
    urlFilter
  ]);

  useEffect(() => {
    handleFilterModels(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },     [
    statusFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType
  ]);

  useEffect(() => {
    if (currentTablePage >= totalModelsPages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalModelsPages]);

  function handleFilterModels(_data: Number): void {
    dispatch(fetchModels(paramsForModelTable));
  }

  const columnsForModelTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      minWidth: 180,
      sortable: false,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
        eraser: () => {allFiltersChange.current=true; setIdFilter("");}
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "name",
      field: "name",
      headerName: "Name",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.name"),
        type: "text",
        value: nameFilter,
        setter: setNameFilter,
        eraser: () => {allFiltersChange.current=true; setNameFilter("");}
      },
    },
    {
      id: "description",
      field: "description",
      headerName: "Description",
      minWidth: 300,
      sortable: false,
      filter: {
        name: t("admin.filtering.description"),
        type: "text",
        value: descriptionFilter,
        setter: setDescriptionFilter,
        eraser: () => {allFiltersChange.current=true; setDescriptionFilter("");}
      },
    },
    {
      id: "url",
      field: "url",
      headerName: "URL",
      minWidth: 400,
      sortable: false,
      filter: {
        name: "URL",
        type: "text",
        value: urlFilter,
        setter: setUrlFilter,
        eraser: () => {allFiltersChange.current=true; setUrlFilter("");}
      },
    },
  ];

  const modelToEdit = modelsData?.find(({ id }) => id === selectedModelId);

  useEffect(() => {
    setNameInModal(modelToEdit?.name || "");
    setDescriptionInModal(modelToEdit?.description || "");
    setUrlInModal(modelToEdit?.url || "");
  }, [modelToEdit]);

  const handleOnDeleteConfirm = () => {
    if (selectedModelId) {
      dispatch(deleteModel(selectedModelId, paramsForModelTable));
    }
  };

  const clearForm = () => {
    setSelectedModelId(undefined);
    setNameInModal("");
    setDescriptionInModal("");
    setUrlInModal("");
  };

  const handleOnClose = () => {
    setSelectedModelId(undefined);
    setShowModal(false);
    clearForm();
  };

  const handleOnSave = () => {
    const modelPayload = {
      name: nameInModal,
      description: descriptionInModal,
      url: urlInModal,
    };

    if (selectedModelId) {
      dispatch(editModel(selectedModelId, modelPayload, paramsForModelTable));
      clearForm();
    } else {
      dispatch(createModel(modelPayload, paramsForModelTable));
      clearForm();
    }
  };

  const isContinueButtonDisabled =
    !nameInModal || !descriptionInModal || !urlInModal;

  const getCreateModelModal = () => {
    return (
      <PungoModal
        open={showModal}
        classNames={styles.modalParentForEdit}
        title={selectedModelId ? `${t("model.edit")}` : `${t("model.create")}`}
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedModelId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: isContinueButtonDisabled,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("model.nameLabel")}`}
            value={nameInModal}
            onChange={setNameInModal}
          />
          <PungoInput
            name={`${t("model.description")}`}
            value={descriptionInModal}
            onChange={setDescriptionInModal}
          />
          <PungoInput name="URL" value={urlInModal} onChange={setUrlInModal} />
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setNameFilter(initialNameFilter);
    setDescriptionFilter(initialDescriptionFilter);
    setUrlFilter(initialUrlFilter);
    allFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    nameFilter !== initialNameFilter ||
    descriptionFilter !== initialDescriptionFilter ||
    urlFilter !== initialUrlFilter;

  return (
    <div>
      <AdminTable
        columns={columnsForModelTable}
        data={modelsData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedModelId}
        actions={["add", "delete", "edit"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalModelsPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateModelModal()}
    </div>
  );
};

export default AdminModelsTable;
