import "./App.css";
import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import instance from "./translations/i18n";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import AstroPage from "./components/AstroPage";
import SnackbarContainer from "./components/SnackbarContainer";
import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import DemoPage from "./components/DemoPage";
import SurveyPage from "./components/SurveyPage";
import ErrorPage from "./components/ErrorPage";
import ResultPage from "./components/ResultPage";
import ChangePasswordPage from "./components/ChangePasswordPage";
import AdminProfilePage from "./components/AdminProfilePage";
import RecoveryAccountPage from "./components/RecoveryAccountPage";
import { withLayout } from "./components/Layout";
import { store } from "./store";
import { clearSnackbarAction } from "store/actions/snackbarActions";
import TermsPage from "components/TermsPage";
import CompanyAdminPage from "components/Admin/CompanyAdminPage";
import AdminPage from "components/Admin/AdminPage";
import PlanSelectionPage from "components/PlanSelectionPage";
import PlanInfoPage from "components/PlanInfoPage";
import FAQPage from "components/FAQPage";
import ContactUsFAQPage from "components/ContactUsFAQPage";
import CalculationOnlinePage from "components/CalculationOnlinePage";
import CalculationBatchPage from "components/CalculationBatchPage";
import CalculationDemoBatchPage from "components/CalculationDemoBatchPage";

const App = () => {
  const persistor = persistStore(store);
  const onBeforeLift = () => {
    store.dispatch(clearSnackbarAction());
  };

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate onBeforeLift={onBeforeLift} loading={null} persistor={persistor}>
          <Router>
            <Fragment>
              <I18nextProvider i18n={instance}>
                <Routes>
                  <Route path="/" element={withLayout(<AstroPage />, undefined, "home")} />
                  <Route path="/login" element={withLayout(<LoginPage />)} />
                  <Route path="/signup" element={withLayout(<SignUpPage />)} />
                  <Route path="/password-change" element={withLayout(<RecoveryAccountPage />)} />
                  <Route path="/password" element={withLayout(<ChangePasswordPage />, "passwordManagement")} />
                  <Route path="/admin-profile" element={withLayout(<AdminProfilePage />, "profileManagement")} />
                  <Route path="/survey" element={withLayout(<SurveyPage />, "survey")} />
                  <Route path="*" element={<ErrorPage type="error" />} />
                  <Route path="/error-u" element={<ErrorPage type="unknow" />} />
                  <Route path="/unauthorized" element={<ErrorPage type="unauthorized" />} />
                  <Route path="/survey/result" element={withLayout(<ResultPage />, "results")} />
                  <Route path="/plan" element={withLayout(<DemoPage />, "demo", "plan")} />
                  <Route path="/admin" element={withLayout(<AdminPage />, "userManagement")} />
                  <Route path="/company-admin" element={withLayout(<CompanyAdminPage />, "companyManagement")} />
                  <Route path="/terms" element={withLayout(<TermsPage />)} />
                  <Route path="/plan-select" element={withLayout(<PlanSelectionPage />, undefined, "planSelection")} />
                  <Route path="/plan-info" element={withLayout(<PlanInfoPage />, undefined, "planSelection")} />
                  <Route path="/faq-page" element={withLayout(<FAQPage />, undefined, "planSelection")} />
                  <Route path="/contactus-page" element={withLayout(<ContactUsFAQPage />, undefined, "planSelection")} />
                  <Route path="/calculation-online" element={withLayout(<CalculationOnlinePage />, undefined, "planSelection")} />
                  <Route path="/calculation-batch" element={withLayout(<CalculationBatchPage />, undefined, "planSelection")} />
                  <Route path="/calculation-demo-batch" element={withLayout(<CalculationDemoBatchPage />, undefined, "planSelection")} />
                </Routes>
              </I18nextProvider>
            </Fragment>
          </Router>
          <SnackbarContainer />
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
