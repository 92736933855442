import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import json2mq from "json2mq";
import { useMediaQuery } from "@mui/material";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import star from "../../../../assets/PungoIcons/Star.svg";
import PungoButton from "pungo-ui/PungoButton";
import {
  saveSelectedPlan,
  selectSelectedPlan,
} from "store/slices/plan";

import breakpoints from "../../../../styles/export.module.scss";
import classNames from "classnames";
import styles from "./index.module.scss";

const DemoPlanSelectorSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPlan = useSelector(selectSelectedPlan);

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.md,
    })
  );

  return (
    <PungoSectionCard title={""}>
      <div className={styles.buttonContainer}>
        <PungoButton
          classNames={classNames(styles.button, {
            [styles.selectedMenu]: selectedPlan === "free",
          })}
          label={`${t("DemoPage.PlanButtons.freePlan")}`}
          onClick={()=>dispatch(saveSelectedPlan("free"))}
        />
        <PungoButton
          classNames={classNames(styles.proPlanButton, {
            [styles.selectedProMenu]: selectedPlan === "pro",
          })}
          label={`${t("DemoPage.PlanButtons.proPlan")}`}
          icon={
            <img
              src={star}
              alt=""
              className={styles.star}
              height={desktopView ? "32px" : "20px"}
            />
          }
          iconPosition={"right"}
          onClick={()=>dispatch(saveSelectedPlan("pro"))}
        />
      </div>
    </PungoSectionCard>
  );
};

export default DemoPlanSelectorSection;
