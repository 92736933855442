import React from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PungoButton from "../../pungo-ui/PungoButton";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import { useAppDispatch } from "app/hooks";
import { logOutSession } from "store/actions/authActions";
import { selectAuthUser } from "store/slices/auth";
import Alas from "../../assets/PungoIcons/Alas.svg";
import styles from "./index.module.scss";

interface ErrorPageProps {
  type: "error" | "unauthorized" | "unknow";
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectAuthUser);

  const getErrorTitle = (error:string)=>{
    switch (error) {
      case 'error':
        return t("errorPageTitle");
      case 'unauthorized':
        return t("missingPermissionsTitle");
      default:
        return t("errorUnknowTitle");
    }
  }

  const getErrorBody = (error:string)=>{
    switch (error) {
      case 'error':
        return t("errorPageBody");
      case 'unauthorized':
        return t("missingPermissionsBody");
      default:
        return t("errorUnknowBody");
    }
  }

  const handleClick = (error:string) => {
    if (error === "unknow"){
      if (userInfo) {
        dispatch(logOutSession({ id_token: userInfo.id_token }));
      }
    }
    navigate("/");
    
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{getErrorTitle(type)}</div>
        <div className={styles.body}>
          <Trans>
            {getErrorBody(type)}
          </Trans>
        </div>
        <div className={styles.button}>
          <PungoButton
            label={`${t("errorPageButton")}`}
            onClick={()=>handleClick(type)}
            color="tertiary"
          />
        </div>
      </div>
      <img className={styles.gif} src={Alas} alt="Text animation" />
      <PungoSelectLanguage />
    </div>
  );
};

export default ErrorPage;
