import { useRef, useEffect, useState } from "react";
import { Chart, ScatterController, PointElement } from 'chart.js';
import { ChartConfiguration } from "chart.js";
import styles from "./index.module.scss";
import colors from "../../styles/export.module.scss";

Chart.register(ScatterController, PointElement);

interface CustomChartConfiguration extends ChartConfiguration {
  legend?: {
    display?: boolean;
  };
}

interface PungoXYGraphProps {
  dataX: any;
  dataY: any;
  options: any;
}

interface DataPoint {
  x: number;
  y: number;
}


const PungoXYGraph: React.FC<PungoXYGraphProps> = ({ dataX, dataY, options }) => {
  const blockRenderFlag = useRef(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const jsonData: DataPoint[] = [];

  const blockRender = () => {
    blockRenderFlag.current = true;
    setTimeout(() => {
      blockRenderFlag.current = false;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 300);
  };

  dataX.map((value: number, index: number) => {
    return (jsonData.push({
      x: dataX[index],
      y: dataY[index],
    }));
  });

  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");
    if (!ctx) return;

    const myChart = new Chart(ctx, {
      type: "scatter",
      data: {
        datasets: [
          {
            label: " ",

            data: jsonData,
            borderColor: colors.red1,
            borderWidth: 1,

            pointStyle: "circle",
            pointBackgroundColor: colors.red1,
            pointBorderColor: colors.red1,
            pointRadius: 1.5,
            showLine: true,
            // tension: 0.4,
          },
        ],
      },
      options: options,
    } as CustomChartConfiguration);

    return () => {
      myChart.destroy();
    };
    // eslint-disable-next-line
  }, [options, windowSize]);

  useEffect(() => {
    const handleResize = () => {
      if (!blockRenderFlag.current) {
        blockRender();

        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.chartContainer}>
      <canvas id="chartCanvas" ref={chartRef} className={styles.canvas} />
    </div>
  );
};

export default PungoXYGraph;
