import React, {useEffect, useRef, useState} from "react";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PungoInput from "pungo-ui/PungoInput";
import PungoModal from "pungo-ui/PungoModal";
import {
  fetchUsageById,
  fetchUsageResume,
  fetchUserDetailsById,
} from "store/actions/userActions";
import {
  clearUsageDetailById,
  selectGeneralUsage,
  selectUsageById,
  selectUsageResumeTotalPages,
  selectUserDetailById,
} from "store/slices/user";
import AdminTable, { ITableColumn } from "../../../common/AdminTable";

import styles from "./index.module.scss";

const AdminUsageTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const totalResumePages = useSelector(selectUsageResumeTotalPages);
  const usageData = useSelector(selectGeneralUsage);
  const detailedData = useSelector(selectUsageById);
  const detailedUserData = useSelector(selectUserDetailById);

  const [pageSize, setPageSize] = useState(10);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();

  const initialTimeFilter = "";
  const initialIdFilter = "";
  const initialUserIdFilter = "";
  const initialUserNameFilter = "";
  const initialCallTypeFilter = "all";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [timeFilter, setTimeFilter] = useState(initialTimeFilter);
  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [userIdFilter, setUserIdFilter] = useState(initialUserIdFilter);
  const [userNameFilter, setUserNameFilter] = useState(initialUserNameFilter);
  const [callTypeFilter, setCallTypeFilter] = useState(initialCallTypeFilter);

  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [filteredUsageTimeoutId, setFilteredUsageTimeoutId] = useState(0);

  const [name, setName] = useState("");
  const [unverifiedCompany, setUnverifiedCompany] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const filterCallTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.proPlan"), value: "PRO_PLAN" },
    { label: t("admin.filtering.simplePlan"), value: "SIMPLE_PLAN" },
  ];

  const columnsForUsageTable: ITableColumn[] = [
    {
      id: "time",
      field: "time",
      headerName: "Time",
      sortable: false,
      minWidth: 180,
      filter: {
        name: t("admin.filtering.date"),
        type: "text",
        value: timeFilter,
        setter: setTimeFilter,
        eraser: () => {allFiltersChange.current=true; setTimeFilter("");}
      },
    },
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 150,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
        eraser: () => {allFiltersChange.current=true; setIdFilter("");}
      },
    },
    {
      id: "userId",
      field: "userId",
      headerName: "User Id",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.userId"),
        type: "text",
        value: userIdFilter,
        setter: setUserIdFilter,
        eraser: () => {allFiltersChange.current=true; setUserIdFilter("");}
      },
    },
    {
      id: "userName",
      field: "userName",
      headerName: "User's email",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: userNameFilter,
        setter: setUserNameFilter,
        eraser: () => {allFiltersChange.current=true; setUserNameFilter("");}
      },
    },
    {
      id: "callType",
      field: "callType",
      headerName: "Call type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.type"),
        type: "list",
        value: callTypeFilter,
        setter: setCallTypeFilter,
        options: filterCallTypeOptions,
      },
    },
  ];

  const paramsForUsageResumeTable = {
    ...(timeFilter && { time: timeFilter }),
    ...(idFilter && { id: idFilter }),
    ...(userIdFilter && { user_id: userIdFilter }),
    ...(userNameFilter && { user_email: userNameFilter }),
    ...(callTypeFilter !== "all" && { dtype: callTypeFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  /**
   * Usage filter options
   */
  useEffect(() => {
    if(!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterUsage(0);
        allFiltersChange.current = false;
      } else {
        if (filteredUsageTimeoutId) {
          clearTimeout(filteredUsageTimeoutId);
        }
        setFilteredUsageTimeoutId(setTimeout(handleFilterUsage, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    timeFilter,
    userIdFilter,
    userNameFilter
  ]);

  useEffect(() => {
    handleFilterUsage(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },     [
    callTypeFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType
  ]);

  useEffect(() => {
    if (currentTablePage >= totalResumePages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalResumePages]);

  function handleFilterUsage(_data: Number): void {
    dispatch(fetchUsageResume(paramsForUsageResumeTable));
  }

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsageId]);

  useEffect(() => {
    setSelectedUserId(detailedData.callDetails.userId);
  }, [detailedData]);

  useEffect(() => {
    setName(detailedUserData?.name || "");
    setUnverifiedCompany(detailedUserData?.unverifiedCompanyName || "");
    setCompany(detailedUserData?.company?.name || "");
    setEmail(detailedUserData?.email || "");
    setRole(detailedUserData?.role || "");
  }, [detailedUserData]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUserDetailsById(selectedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const clearForm = () => {
    setName("");
    setUnverifiedCompany("");
    setCompany("")
    setEmail("");
  };

  const handleOnClose = () => {
    setShowUsageModal(false);
    setShowUserModal(false);
    setSelectedUserId(undefined);
    setSelectedUsageId(undefined);
    dispatch(clearUsageDetailById());
    clearForm();
  };

  const resetFilters = () => {
    setTimeFilter(initialTimeFilter);
    setIdFilter(initialIdFilter);
    setUserIdFilter(initialUserIdFilter);
    setUserNameFilter(initialUserNameFilter);
    setCallTypeFilter(initialCallTypeFilter);
    allFiltersChange.current=true;
  };

  const showResetFilters =
    timeFilter !== initialTimeFilter ||
    idFilter !== initialIdFilter ||
    userIdFilter !== initialUserIdFilter ||
    userNameFilter !== initialUserNameFilter ||
    callTypeFilter !== initialCallTypeFilter;

  const getUserDetailsModal = () => (
    <PungoModal
      open={showUserModal}
      title={`${t("admin.userDetailsLabel")}`}
      handleClose={handleOnClose}
      primaryAction={{
        label: "Ok",
        onClick: handleOnClose,
      }}
    >
      <div className={styles.modal}>
        <PungoInput
          name={`${t("user.nameLabel")}`}
          value={name}
          onChange={setName}
          disabled
        />
        {unverifiedCompany && (
          <PungoInput
            name={`${t("user.unverifiedCompanyLabel")}`}
            value={unverifiedCompany}
            disabled
            onChange={setUnverifiedCompany}
          />
        )}
        {company && (
            <PungoInput
                name={`${t("user.companyLabel")}`}
                value={company}
                disabled
                onChange={setCompany}
            />
        )}
        <PungoInput
          name={`${t("user.emailLabel")}`}
          value={email}
          onChange={setEmail}
          disabled
        />
        <PungoInput
          name={`${t("user.roleLabel")}`}
          value={role}
          onChange={setRole}
          disabled
        />
      </div>
    </PungoModal>
  );

  return (
    <div>
      <AdminTable
        columns={columnsForUsageTable}
        data={usageData || []}
        actions={["stats", "user"]}
        handleOnSelection={setSelectedUsageId}
        handleOnUsage={setShowUsageModal}
        handleOnUser={setShowUserModal}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalResumePages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      <PungoModal
        open={showUsageModal}
        classNames={styles.detailsModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnClose}
        primaryAction={{
          label: "Ok",
          onClick: handleOnClose,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(detailedData, null, 4)}</pre>
        </code>
      </PungoModal>
      {getUserDetailsModal()}
    </div>
  );
};

export default AdminUsageTable;
