import React, {useEffect, useRef, useState} from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import {
  createChoice,
  createQuestion,
  deleteChoice,
  deleteQuestion,
  editChoice,
  editQuestion,
  fetchFilteredQuestions,
} from "store/actions/questionActions";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import {
  selectQuestions,
  selectTotalQuestionsPages,
} from "store/slices/question";

import styles from "./index.module.scss";

const AdminQuestionsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const questionsData = useSelector(selectQuestions);
  const totalQuestionsPages = useSelector(selectTotalQuestionsPages);

  const [selectedQuestionId, setSelectedQuestionId] = useState<
    number | undefined
  >();
  const [selectedChoiceId, setSelectedChoiceId] = useState<
    number | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [showChoiceModal, setShowChoiceModal] = useState(false);

  const [number, setNumber] = useState(0);
  const [type, setType] = useState("");
  const [spanishName, setSpanishName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [numberChoice, setNumberChoice] = useState(0);
  const [score, setScore] = useState(0);
  const [spanishChoiceName, setSpanishChoiceName] = useState("");
  const [englishChoiceName, setEnglishChoiceName] = useState("");

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialQuestionNumberFilter = "";
  const initialQuestionTypeFilter = "all";
  const initialTextEsFilter = "";
  const initialTextEnFilter = "";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [questionNumberFilter, setQuestionNumberFilter] = useState(
    initialQuestionNumberFilter
  );
  const [questionTypeFilter, setQuestionTypeFilter] = useState(
    initialQuestionTypeFilter
  );
  const [questionTextEsFilter, setQuestionTextEsFilter] =
    useState(initialTextEsFilter);
  const [questionTextEnFilter, setQuestionTextEnFilter] =
    useState(initialTextEnFilter);

  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [filteredQuestionsTimeoutId, setFilteredQuestionsTimeoutId] =
    useState(0);

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const filterTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.single"), value: "SINGLE_SELECTION" },
    { label: t("admin.filtering.multiple"), value: "MULTIPLE_SELECTION" },
  ];

  const columnsForQuestionsTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 180,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
        eraser: () => {allFiltersChange.current=true; setIdFilter("");}
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "number",
      field: "number",
      headerName: "Number",
      sortable: false,
      minWidth: 180,
      filter: {
        name: t("admin.filtering.questionNumber"),
        type: "text",
        value: questionNumberFilter,
        setter: setQuestionNumberFilter,
        eraser: () => {allFiltersChange.current=true; setQuestionNumberFilter("");}
      },
    },
    {
      id: "type",
      field: "type",
      headerName: "Type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.type"),
        type: "list",
        value: questionTypeFilter,
        setter: setQuestionTypeFilter,
        options: filterTypeOptions,
      },
    },
    {
      id: "textEs",
      field: "textEs",
      headerName: "Question",
      sortable: false,
      minWidth: 250,
      filter: {
        name: t("admin.filtering.esQuestion"),
        type: "text",
        value: questionTextEsFilter,
        setter: setQuestionTextEsFilter,
        eraser: () => {allFiltersChange.current=true; setQuestionTextEsFilter("");}
      },
    },
    {
      id: "textEn",
      field: "textEn",
      headerName: "Question",
      sortable: false,
      minWidth: 250,
      filter: {
        name: t("admin.filtering.enQuestion"),
        type: "text",
        value: questionTextEnFilter,
        setter: setQuestionTextEnFilter,
        eraser: () => {allFiltersChange.current=true; setQuestionTextEnFilter("");}
      },
    },
  ];

  const columnsForChoicesTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 180,
    },
    {
      id: "number",
      field: "number",
      headerName: "Number",
      sortable: false,
      minWidth: 120,
    },
    {
      id: "score",
      field: "score",
      headerName: "Score",
      sortable: false,
      minWidth: 120,
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
    },
    {
      id: "textEn",
      field: "textEn",
      headerName: "Answer",
      sortable: false,
      minWidth: 200,
    },
    {
      id: "textEs",
      field: "textEs",
      headerName: "Answer",
      sortable: false,
      minWidth: 200,
    },
  ];

  const paramsForQuestionsTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(questionNumberFilter && { number: questionNumberFilter }),
    ...(questionTypeFilter !== "all" && { type: questionTypeFilter }),
    ...(questionTextEsFilter && { textEs: questionTextEsFilter }),
    ...(questionTextEnFilter && { textEn: questionTextEnFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  /**
   * Question filter effects
   */
  useEffect(() => {
    if(!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterQuestions(0);
        allFiltersChange.current = false;
      } else {
        if (filteredQuestionsTimeoutId) {
          clearTimeout(filteredQuestionsTimeoutId);
        }
        setFilteredQuestionsTimeoutId(setTimeout(handleFilterQuestions, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    questionNumberFilter,
    questionTextEsFilter,
    questionTextEnFilter
  ]);

  useEffect(() => {
    handleFilterQuestions(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },     [
    statusFilter,
    questionTypeFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType
  ]);

  useEffect(() => {
    if (currentTablePage >= totalQuestionsPages) {
      setCurrentTablePage(0);
    }
  }, [
    currentTablePage,
    totalQuestionsPages
  ]);

  function handleFilterQuestions(_data: Number): void {
    dispatch(fetchFilteredQuestions(paramsForQuestionsTable));
  }

  const questionToEdit = questionsData.find(
    ({ id }) => id === selectedQuestionId
  );

  const choiceToEdit = questionToEdit?.choices.find(
    ({ id }) => id === selectedChoiceId
  );

  useEffect(() => {
    setNumber(questionToEdit?.number || 0);
    setType(questionToEdit?.type || "");
    setSpanishName(questionToEdit?.textEs || "");
    setEnglishName(questionToEdit?.textEn || "");
  }, [questionToEdit]);

  useEffect(() => {
    setNumberChoice(choiceToEdit?.number || 0);
    setScore(choiceToEdit?.score || 0);
    setSpanishChoiceName(choiceToEdit?.textEs || "");
    setEnglishChoiceName(choiceToEdit?.textEn || "");
  }, [choiceToEdit]);

  const handleOnDeleteConfirm = () => {
    if (selectedQuestionId) {
      dispatch(deleteQuestion(selectedQuestionId, paramsForQuestionsTable));
    }
  };
  const handleOnDeleteChoiceConfirm = () => {
    if (selectedQuestionId && selectedChoiceId) {
      dispatch(
        deleteChoice(
          selectedQuestionId,
          selectedChoiceId,
          paramsForQuestionsTable
        )
      );
    }
  };

  const clearForm = () => {
    setSelectedQuestionId(undefined);
    setNumber(0);
    setType("");
    setSpanishName("");
    setEnglishName("");
  };

  const clearChoiceForm = () => {
    setSelectedChoiceId(undefined);
    setNumberChoice(0);
    setScore(0);
    setSpanishChoiceName("");
    setEnglishChoiceName("");
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setQuestionNumberFilter(initialQuestionNumberFilter);
    setQuestionTypeFilter(initialQuestionTypeFilter);
    setQuestionTextEsFilter(initialTextEsFilter);
    setQuestionTextEnFilter(initialTextEnFilter);
    allFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    questionNumberFilter !== initialQuestionNumberFilter ||
    questionTypeFilter !== initialQuestionTypeFilter ||
    questionTextEsFilter !== initialTextEsFilter ||
    questionTextEnFilter !== initialTextEnFilter;

  const handleOnClose = () => {
    setShowModal(false);
    clearForm();
  };
  const handleOnCloseChoiceModal = () => {
    setShowChoiceModal(false);
    clearChoiceForm();
  };

  const handleOnSave = () => {
    const questionPayload = {
      number,
      type,
      textEn: englishName,
      textEs: spanishName,
    };
    if (selectedQuestionId) {
      dispatch(
        editQuestion(
          selectedQuestionId,
          questionPayload,
          paramsForQuestionsTable
        )
      );
      clearForm();
    } else {
      dispatch(createQuestion(questionPayload, paramsForQuestionsTable));
      clearForm();
    }
  };
  const handleOnSaveChoice = () => {
    const choicePayload = {
      number: numberChoice,
      textEn: englishChoiceName,
      textEs: spanishChoiceName,
      score,
      question: { number, type, textEn: englishName, textEs: spanishName },
    };
    if (selectedQuestionId && selectedChoiceId) {
      dispatch(
        editChoice(
          selectedQuestionId,
          selectedChoiceId,
          choicePayload,
          paramsForQuestionsTable
        )
      );
      clearChoiceForm();
    } else {
      if (selectedQuestionId) {
        dispatch(
          createChoice(
            selectedQuestionId,
            choicePayload,
            paramsForQuestionsTable
          )
        );
        clearChoiceForm();
      }
    }
  };

  const questionTypeOptions = ["SINGLE_SELECTION", "MULTIPLE_SELECTION"];

  const getCreateQuestionModal = () => {
    return (
      <PungoModal
        open={showModal}
        title={
          selectedQuestionId
            ? `${t("question.editTitle")}`
            : `${t("question.createTitle")}`
        }
        handleClose={handleOnClose}
        classNames={
          selectedQuestionId
            ? styles.modalParentForEdit
            : styles.modalParentForCreate
        }
        primaryAction={{
          label: selectedQuestionId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: !number || !spanishName || !englishName,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.inputs}>
            <PungoInput
              name={`${t("question.numberLabel")}`}
              value={number}
              onChange={setNumber}
              type="number"
            />
            <PungoInput
              name={`${t("question.typeLabel")}`}
              value={type}
              onChange={setType}
              type="select"
              options={questionTypeOptions.map((type) => {
                return { value: type, label: type };
              })}
            />
            <PungoInput
              name={`${t("question.spanishNameLabel")}`}
              value={spanishName}
              onChange={setSpanishName}
            />
            <PungoInput
              name={`${t("question.englishNameLabel")}`}
              value={englishName}
              onChange={setEnglishName}
            />
          </div>
          {selectedQuestionId && (
            <AdminTable
              columns={columnsForChoicesTable}
              data={questionToEdit?.choices || []}
              handleOnEdit={setShowChoiceModal}
              handleOnDelete={handleOnDeleteChoiceConfirm}
              handleOnSelection={setSelectedChoiceId}
              actions={["add", "delete", "edit"]}
              classNames={styles.choicesTable}
            />
          )}
        </div>
      </PungoModal>
    );
  };
  const getCreateChoiceModal = () => {
    return (
      <PungoModal
        open={showChoiceModal}
        title={
          selectedChoiceId
            ? `${t("question.editChoiceTitle")}`
            : `${t("question.createChoiceTitle")}`
        }
        handleClose={handleOnCloseChoiceModal}
        classNames={styles.modalParentForChoice}
        primaryAction={{
          label: selectedChoiceId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSaveChoice,
          disabled:
            !number || !spanishChoiceName || !englishChoiceName || !score,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnCloseChoiceModal,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.inputs}>
            <PungoInput
              name={`${t("question.numberLabel")}`}
              value={numberChoice}
              onChange={setNumberChoice}
              type="number"
            />
            <PungoInput
              name={`${t("question.scoreLabel")}`}
              value={score}
              onChange={setScore}
              type="number"
            />
            <PungoInput
              name={`${t("question.spanishChoiceNameLabel")}`}
              value={spanishChoiceName}
              onChange={setSpanishChoiceName}
            />
            <PungoInput
              name={`${t("question.englishChoiceNameLabel")}`}
              value={englishChoiceName}
              onChange={setEnglishChoiceName}
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  return (
    <div>
      <AdminTable
        columns={columnsForQuestionsTable}
        data={questionsData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedQuestionId}
        actions={["add", "delete", "edit"]}
        handleOnResetFilters={resetFilters}
        shouldShowResetFilters={showResetFilters}
        pagination={{
          totalPages: totalQuestionsPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateQuestionModal()}
      {getCreateChoiceModal()}
    </div>
  );
};

export default AdminQuestionsTable;
