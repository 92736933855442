import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserRole, selectUserName, selectSurveyStatus } from "store/slices/auth";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoAccordion from "pungo-ui/PungoAccordion";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import DataInputSection from "components/DemoPage/sections/DataInputSection";
import ResultsSection from "components/DemoPage/sections/ResultsSection";
import styles from "./index.module.scss";

const CalculationBatchPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [renderPage, setRenderPage] = useState(false);
  const userRol = useSelector(selectUserRole)[0];
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "free_plan", "pro_plan", "company_user", "company_admin"];

    if (!isLogged) {
      navigate("/login");
    }else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    }else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted]);

  const getInfoTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.text}>{t("CalculatorOnline.information.txt1")}</div>
      <div className={styles.text}>{t("CalculatorOnline.information.txt2")}</div>
    </div>
  );
  const getInstTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.instructionTitle}>{t("CalculatorOnline.instructions.title")} </div>
      <ol>
        <li> {t("CalculatorOnline.instructions.step01")} </li>
        <li> {t("CalculatorOnline.instructions.step02")} </li>
        <li> {t("CalculatorOnline.instructions.step03")} </li>
        <li> {t("CalculatorOnline.instructions.step04")} </li>
        <li> {t("CalculatorOnline.instructions.step05")} </li>
        <li> {t("CalculatorOnline.instructions.step06")} </li>
      </ol>
      <div className={styles.notes}> {t("CalculatorOnline.instructions.note01")} </div>
    </div>
  );

  return (
    <>
      {renderPage && (
        <>
          <PungoSectionCard title=" ">
            <div className={styles.CalculationBatchPage}>
              <div className={styles.title}>{t("CalculatorOnline.title")}</div>
              <PungoAccordion title={t("PlanSelect.whatIs")} content={getInfoTxt()} noborder={true} />
              <PungoAccordion title={t("PlanSelect.howTo")} content={getInstTxt()} noborder={true} />
              <DataInputSection />
              <ResultsSection />
            </div>
          </PungoSectionCard>
          <FooterOnePageSection />
          <PungoSelectLanguage />
        </>
      )}
    </>
  );
};

export default CalculationBatchPage;
