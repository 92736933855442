import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectSurveyStatus, selectUserRole } from "store/slices/auth";
import AnimationSection from "./sections/AnimationSection";
import DataInputSection from "./sections/DataInputSection";
import InstructionsSection from "./sections/InstructionsSection";
import ResultsSection from "./sections/ResultsSection";
import PlanTableSection from "components/AstroComponents/PlanTableSection";
import FAQSection from "components/AstroComponents/FAQSection";
import ContactUsSection from "components/AstroComponents/ContactUsSection";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import PlanSelectorSection from "./sections/DemoPlanSelectorSection";
import { selectFetchedProResultsStatus, selectSelectedPlan } from "store/slices/plan";
import PlanInformationSection from "./sections/PlanInformationSection";
import DataInputProSection from "./sections/DataInputProSection";
import ResultsProSection from "./sections/ResultsProSection";
import { fetchMockedData } from "store/actions/planActions";
import { useAppDispatch } from "app/hooks";

import styles from "./index.module.scss";

const DemoPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const selectedPlan = useSelector(selectSelectedPlan);
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");
  const isCompanyUser = userRoles.includes("company_admin") || userRoles.includes("company_user");

  useEffect(() => {
    if (isCompanyUser) {
      navigate("/plan-select");
    } else if (!isSurveyCompleted) {
      navigate("/survey");
    } 
  }, [isSurveyCompleted, isCompanyUser, navigate]);

  useEffect(() => {
    if (isFreeUser) {
      dispatch(fetchMockedData());
    }
  }, [dispatch, isFreeUser]);

  return (
    <section className={styles.container}>
      <AnimationSection />
      {!isCompanyUser && <PlanSelectorSection />}
      <PlanInformationSection />
      <InstructionsSection />
      {selectedPlan === "free" ? <DataInputSection /> : areProResultsFetched ? <DataInputProSection /> : undefined}
      {selectedPlan === "free" ? <ResultsSection /> : areProResultsFetched ? <ResultsProSection /> : undefined}
      {!isCompanyUser && <PlanTableSection />}
      <FAQSection />
      <ContactUsSection />
      <FooterOnePageSection />
    </section>
  );
};

export default DemoPage;
