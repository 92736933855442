import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";

import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { store } from "..";
import { clerUserSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import {
  IPlanDetailsPerCompany,
  saveCompanies,
  saveCompaniesList,
  saveTotalCompaniesPages,
  saveTotalUsagePages,
  saveUsages,
  saveWellModelAssignations,
} from "store/slices/company";

const companyURL = getBaseUrlForAPI("company");
const groupURL = getBaseUrlForAPI("group");

interface IPlanDetailsParams {
  planUsageType?: string;
  planRemainingUsages?: number;
  planEndDate?: string;
  planPaymentType?: string;
}

export interface ICompanyParams {
  id?: string;
  status?: string;
  name?: string;
  contactName?: string;
  contactEmail?: string;
  notificationEmails?: string;
  planDetails?: IPlanDetailsParams;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export interface IUsageParams {
  id?: string;
  userId?: string;
  userEmail?: string;
  toDate?: string;
  FromDate?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export interface ICreateCompany {
  name: string;
  contactName: string;
  contactEmail: string;
  notificationEmails: string;
  planDetails: IPlanDetailsPerCompany;
}

export const fetchCompanies =
  (params?: ICompanyParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchCompanies-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${companyURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveCompanies(data.content));
            dispatch(saveTotalCompaniesPages(data.totalPages));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createCompany =
  (company: ICreateCompany, params: ICompanyParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${companyURL}`, company)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelCreated"));
            dispatch(fetchCompanies(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteCompany =
  (companyId: number, params: ICompanyParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${companyURL}/${companyId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelDeleted"));
            dispatch(fetchCompanies(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editCompany =
  (companyId: number, company: ICreateCompany, params: ICompanyParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${companyURL}/${companyId}`, company)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelEdited"));
            dispatch(fetchCompanies(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const getModelAssignationByCompany =
  (companyId: number) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getModelAssignationByCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${companyURL}/${companyId}/assignModel`)
          .then(({ data }) => {
            dispatch(saveWellModelAssignations(data.wellAssignations));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const assignModelsToCompany =
  (companyId: number, assignation: any) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${companyURL}/${companyId}/assignModel`, assignation)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelsAssigned"));
            dispatch(getModelAssignationByCompany(companyId));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const getModelAssignationByCompanyForCompanyAdmin =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getModelAssignationByCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/company/assignModel`)
          .then(({ data }) => {
            dispatch(saveWellModelAssignations(data.wellAssignations));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchFilteredUsagesForCompanyAdmin =
  (params: IUsageParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchFilteredUsagesForCompanyAdmin-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/app-usage/search`, { params })
          .then(({ data }) => {
            dispatch(saveUsages(data.content));
            dispatch(saveTotalUsagePages(data.totalPages));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchAvailableCompanyList =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchAvailableCompanyList-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${companyURL}/option-list`)
          .then(({ data }) => {
            dispatch(saveCompaniesList(data));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };