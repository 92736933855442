import React from "react";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import FAQSection from "components/AstroComponents/FAQSection";
import ContactUsSection from "components/AstroComponents/ContactUsSection";
import styles from "./index.module.scss";

const FAQPage: React.FC = () => {

  return (
    <div className={styles.FAQPage}>
      <FAQSection />
      <ContactUsSection />
      <FooterOnePageSection />

    </div>
  );
};

export default FAQPage;
