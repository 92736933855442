import React from "react";
import { useTranslation } from "react-i18next";

import PungoButton from "../../../pungo-ui/PungoButton";
import PungoClickable from "../../../pungo-ui/PungoClickable";
import PungoSectionCard from "../../../pungo-ui/PungoSectionCard";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";

import styles from "./index.module.scss";

const ContactUsSection: React.FC = () => {
  const { t } = useTranslation();

  const getBookingPage = () => window.open("https://marketing.pungoapp.com/meetings/santiago-zapata2588/santiago-zapata");

  const sendWhastapp = () => {
    let url: string = "https://api.whatsapp.com/send/?";
    let phone: string = `phone= 447442697610`;
    let msg: string = `&text=${t("sectionContent.whatsAppMessage")}`;
    let tale: string = "&type=phone_number&app_absent=0";

    msg = msg.replaceAll(" ", "+");
    url += phone + msg + tale;

    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <PungoSectionCard title={t("sectionLabel.contactUs")} description={`${t("sectionContent.contactUs")}`} id="contactUs" distribution="block">
        <div className={styles.container}>
          <div className={styles.scheduleMeeting}>
            <PungoButton label={`${t("bookAMeetingLabel")}`} onClick={getBookingPage} classNames={styles.bookingButton} color="secondary" />
          </div>
          <div className={styles.contactLinksContainer}>
            <PungoClickable link="https://www.linkedin.com/company/pungosolutions/" classNames={styles.linkedInButton}>
              in
            </PungoClickable>
            <div className={styles.whatsappButton} onClick={() => sendWhastapp()}></div>
          </div>
        </div>
      </PungoSectionCard>
      <PungoSelectLanguage/>
    </>
  );
};

export default ContactUsSection;
