import React, {useEffect, useState, ChangeEvent, useRef} from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import { isEmail } from "components/utils/validations";
import {
  createUserPerCompany,
  deleteUsersPerCompany,
  editUserPerCompany,
  getFilteredUsersPerCompany,
  getUserPerCompanyRoles,
} from "store/actions/userCompanyActions";
import {
  selectAllRolesUserPerCompany,
  selectAllUsersPerCompany,
  selectTotalUsersPerCompanyPages,
} from "store/slices/userCompany";

import { selectUserName } from "store/slices/auth";

import styles from "./index.module.scss";

const UsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usersData = useSelector(selectAllUsersPerCompany);
  const rolesList = useSelector(selectAllRolesUserPerCompany);
  const totalUserPages = useSelector(selectTotalUsersPerCompanyPages);

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialRoleFilter = "all";
  const initialNameFilter = "";
  const initialMailFilter = "";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [roleFilter, setRoleFilter] = useState(initialRoleFilter);
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [mailFilter, setMailFilter] = useState(initialMailFilter);

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [showMainModal, setShowMainModal] = useState(false);
  const [filteredUsersCallTimeoutId, setFilteredUsersTimeoutId] = useState(0);

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const paramsUsersPerCompanyTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(nameFilter && { name: nameFilter }),
    ...(mailFilter && { email: mailFilter }),
    ...(roleFilter !== "all" && { role: roleFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  useEffect(() => {
    dispatch(getUserPerCompanyRoles());
  }, []);

  /**
   * User filter options
   */
  useEffect(() => {
    if(!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterUsers(0);
        allFiltersChange.current = false;
      } else {
        if (filteredUsersCallTimeoutId) {
          clearTimeout(filteredUsersCallTimeoutId);
        }
        setFilteredUsersTimeoutId(setTimeout(handleFilterUsers, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    nameFilter,
    mailFilter
  ]);

  useEffect(() => {
    handleFilterUsers(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },     [
    statusFilter,
    roleFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType
  ]);

  useEffect(() => {
    if (currentTablePage >= totalUserPages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalUserPages]);

  function handleFilterUsers(_data: Number): void {
    dispatch(getFilteredUsersPerCompany(paramsUsersPerCompanyTable));
  }

  const columnsForUserPerCompanyTable: ITableColumn[] = [
    {
      id: "status",
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "role",
      field: "role",
      headerName: "Role",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.role"),
        type: "list",
        value: roleFilter,
        setter: setRoleFilter,
        options: rolesList
          .map(({ name }) => {
            return { label: name, value: name };
          })
          .concat({
            value: "all",
            label: t("admin.filtering.all"),
          }),
      },
    },
    {
      id: "name",
      field: "name",
      headerName: "Name",
      minWidth: 400,
      sortable: false,
      filter: {
        name: t("admin.filtering.name"),
        type: "text",
        value: nameFilter,
        setter: setNameFilter,
        eraser: () => {allFiltersChange.current=true; setNameFilter("");}
      },
    },
    {
      id: "email",
      field: "email",
      headerName: "Email",
      minWidth: 400,
      sortable: false,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: mailFilter,
        setter: setMailFilter,
        eraser: () => {allFiltersChange.current=true; setMailFilter("");}
      },
    },
  ];

  const userToEdit = usersData?.find(({ id }) => id === selectedUserId);

  useEffect(() => {
    setName(userToEdit?.name || "");
    setEmail(userToEdit?.email || "");
    setRole(userToEdit?.role || "");
    setIsEmailVerified(userToEdit?.emailVerified || false);
  }, [userToEdit]);

  const handleOnDeleteConfirm = () => {
    if (selectedUserId) {
      dispatch(
        deleteUsersPerCompany(selectedUserId, paramsUsersPerCompanyTable)
      );
    }
  };

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [email]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setEmail("");
    setRole("");
    setPassword("");
    setIsEmailVerified(false);
  };

  const handleOnClose = () => {
    setSelectedUserId(undefined);
    setShowMainModal(false);
    clearForm();
  };

  const handleOnSave = () => {
    const userPayload = {
      username: email,
      name: name,
      email: email,
      ...(password && { password }),
      role,
      emailVerified: isEmailVerified,
    };

    if (selectedUserId) {
      dispatch(
        editUserPerCompany(
          selectedUserId,
          userPayload,
          paramsUsersPerCompanyTable
        )
      );
      clearForm();
    } else {
      dispatch(createUserPerCompany(userPayload, paramsUsersPerCompanyTable));
      clearForm();
    }
  };

  const handleChangeVerifiedMail = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVerified(event.target.checked);
  };

  const isContinueButtonDisabled =
    !name ||
    !email ||
    !role ||
    !isValidEmail ||
    (selectedUserId ? false : !password);

  const getCreateUserModal = () => {
    return (
      <PungoModal
        open={showMainModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedUserId
            ? `${t("admin.editTitle")}`
            : `${t("admin.createTitle")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedUserId ? t("admin.editLabel") : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: isContinueButtonDisabled,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("user.nameLabel")}`}
            value={name}
            onChange={setName}
          />
          <PungoInput
            name={`${t("user.emailLabel")}`}
            value={email}
            onChange={setEmail}
            disabled={!!selectedUserId}
            helperText={
              isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
            }
          />
          <PungoInput
            name={`${t("user.passwordLabel")}`}
            value={password}
            onChange={setPassword}
            type="password"
          />
          <PungoInput
            name={`${t("user.roleLabel")}`}
            value={role}
            onChange={setRole}
            type="select"
            options={rolesList.map(({ name }) => {
              return { value: name, label: name };
            })}
          />
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEmailVerified}
                  onChange={handleChangeVerifiedMail}
                />
              }
              label={t("user.verifiedEmailLabel")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setRoleFilter(initialRoleFilter);
    setNameFilter(initialNameFilter);
    setMailFilter(initialMailFilter);
    allFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    roleFilter !== initialRoleFilter ||
    nameFilter !== initialNameFilter ||
    mailFilter !== initialMailFilter;

  return (
    <div>
      <AdminTable
        columns={columnsForUserPerCompanyTable}
        data={usersData || []}
        handleOnEdit={setShowMainModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedUserId}
        handleOnUsage={setShowMainModal}
        actions={["add", "delete", "edit"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalUserPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateUserModal()}
    </div>
  );
};

export default UsersTable;
