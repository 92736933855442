import React, { useEffect, useState } from "react";

import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import SurveyCard from "./utils/SurveyCard";

import { useAppDispatch } from "app/hooks";
import { getSurvey, sendAnswer } from "store/actions/surveyActions";
import { ISurveyAnswer, ISurveyQuestion, selectAnswerLoader, selectSurvey } from "store/slices/survey";
import { selectAuthUser } from "store/slices/auth";
import { refresh } from "store/actions/authActions";

import styles from "./index.module.scss";

const SurveyPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSendingAnswer = useSelector(selectAnswerLoader);
  const authUserData = useSelector(selectAuthUser);
  const surveyData = useSelector(selectSurvey);
  const [allAnswers, setAllAnswers] = useState<ISurveyAnswer[]>([]);

  const [answer, setAnswer] = useState<number[]>([]);
  const [questionIndexToShow, setQuestionIndexToShow] = useState<number>(0);
  const currentQuestionNumber = surveyData?.questions[questionIndexToShow]?.number || 0;
  const savedChoices = allAnswers.find(({ questionNumber }) => questionNumber === currentQuestionNumber)?.choicesNumbers;
  const lastAnsweredQuestionNumber = surveyData?.questions[surveyData.answers.length - 1]?.number || 0;
  const lastAnsweredQuestionIndex = surveyData?.answers?.findIndex(({ questionNumber }) => questionNumber === lastAnsweredQuestionNumber) || 0;
  const isFirstQuestion = questionIndexToShow === 0;
  const isLastQuestion = questionIndexToShow === surveyData.questions.length - 1;

  const handleAnswers = (question: number, answer: number[]) => {
    const answerIndex = allAnswers.findIndex(({ questionNumber }) => questionNumber === question);

    if (answerIndex < 0) {
      setAllAnswers((prev) => [...prev, { questionNumber: question, choicesNumbers: answer }]);
    } else {
      let updated = allAnswers.map((item) => {
        if (item.questionNumber === question) {
          return { ...item, choicesNumbers: answer };
        } else {
          return item;
        }
      });
      setAllAnswers(updated);
    }
  };

  const refreshSurvey = () => {
    dispatch(getSurvey());
    navigate("/survey/result");
  };

  const handleOnDispatch = () => {
    if (!isLastQuestion) {
      setQuestionIndexToShow((prev) => prev + 1);
    } else if (isLastQuestion) {
      if (authUserData?.refresh_token) {
        dispatch(refresh({ refresh_token: authUserData.refresh_token }, refreshSurvey));
      }
    }
  };

  const handleOnNext = () => {
    dispatch(
      sendAnswer(
        {
          questionNumber: surveyData?.questions[questionIndexToShow]?.number,
          choicesNumbers: answer,
        },
        handleOnDispatch
      )
    );

    handleAnswers(surveyData?.questions[questionIndexToShow]?.number, answer);
  };

  const handleOnPrev = () => {
    if (!isFirstQuestion) {
      setQuestionIndexToShow((prev) => prev - 1);
    }
  };

  useEffect(() => {
    dispatch(getSurvey());
    window.scrollTo(0, 0);
    if (surveyData.questions.length >= 0) {
      if (surveyData?.answers.length) {
        setAllAnswers(surveyData?.answers);
        if (lastAnsweredQuestionIndex > -1) {
          setQuestionIndexToShow(lastAnsweredQuestionIndex);
        }
      }
    }else{
      navigate("/error-u");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentSelection = allAnswers[questionIndexToShow]?.choicesNumbers || [];
    setAnswer(currentSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndexToShow]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>{t("survey.header")}</div>
        {isFirstQuestion && (
          <div className={styles.body}>
            <Trans>{t("survey.body")}</Trans>
          </div>
        )}
        <div className={styles.instructions}>{t("survey.instructions")}</div>
      </div>
      <div>
        <SurveyCard
          question={surveyData?.questions[questionIndexToShow] || ({} as ISurveyQuestion)}
          onChange={setAnswer}
          onNext={handleOnNext}
          onPrev={handleOnPrev}
          isLastQuestion={isLastQuestion}
          isFirstQuestion={isFirstQuestion}
          isLoadingAnswer={isSendingAnswer}
          initialChoices={savedChoices}
          key={currentQuestionNumber}
        />
      </div>
    </div>
  );
};

export default SurveyPage;
