import React from "react";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import PlanByTransactionSection from "components/AstroComponents/PlanByTransactionSection";
import PlanTableSection from "components/AstroComponents/PlanTableSection";
import ContactUsSection from "components/AstroComponents/ContactUsSection";
import styles from "./index.module.scss";

const PlanInfoPage: React.FC = () => {

  return (
    <div className={styles.planInfoPage}>
      <PlanByTransactionSection />
      <PlanTableSection />
      <ContactUsSection />
      <FooterOnePageSection />
    </div>
  );
};

export default PlanInfoPage;
