import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";
import { store } from "../index";
import { clerUserSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import { savePlan, saveProfile } from "store/slices/profile";

interface IUpdateProfile {
  name?: string;
  unverifiedCompanyName?: string;
}

const profileURL = getBaseUrlForAPI("profile");

export const fetchProfileAndPlanInfo =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchProfileAndPlanInfo-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${profileURL}`)
        .then(({ data }) => {
          dispatch(saveProfile(data.profile));
          dispatch(savePlan(data.plan));
        })
        .catch(error => {
          // Error handler when calling instance before login
          console.log("Msg: PA-100, not loged in yet");
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const updateProfile =
  (updatedInfo: IUpdateProfile) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `updateProfile-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .patch(`${profileURL}/profile-update`, updatedInfo)
          .then(() => {
            dispatch(fetchProfileAndPlanInfo());
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };
