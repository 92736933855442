import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";

import { store } from "..";
import { clerUserSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import { saveQuestions, saveTotalQuestionsPages } from "store/slices/question";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";

const questionURL = getBaseUrlForAPI("question");

interface ISurveyQuestion {
  number: number;
  textEs: string;
  textEn: string;
  type: string;
}

interface ISurveyChoice {
  number: number;
  textEs: string;
  textEn: string;
  score: number;
  question: ISurveyQuestion;
}

export interface IQuestionFilter {
  id?: string;
  status?: string;
  number?: string;
  type?: string;
  textEn?: string;
  textEs?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const fetchFilteredQuestions =
  (params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchFilteredQuestions-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${questionURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveQuestions(data.content));
            dispatch(saveTotalQuestionsPages(data.totalPages));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteQuestion =
  (questionId: number, params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteQuestion-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${questionURL}/${questionId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.questionDeleted"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createQuestion =
  (question: ISurveyQuestion, params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createQuestion-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${questionURL}`, question)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.questionCreated"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editQuestion =
  (questionId: number, question: ISurveyQuestion, params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editQuestion-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${questionURL}/${questionId}`, question)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.questionEdited"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createChoice =
  (questionId: number, question: ISurveyChoice, params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createChoice-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${questionURL}/${questionId}/choice`, question)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.choiceCreated"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editChoice =
  (
    questionId: number,
    choiceId: number,
    choice: ISurveyChoice,
    params: IQuestionFilter
  ) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editChoice-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${questionURL}/${questionId}/choice/${choiceId}`, choice)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.choiceEdited"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteChoice =
  (questionId: number, choiceId: number, params: IQuestionFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteChoice-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${questionURL}/${questionId}/choice/${choiceId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.choiceDeleted"));
            dispatch(fetchFilteredQuestions(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };
