import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { displaySnackbarAPISuccess, getProPlanErrorMessage, getSnackbarError, } from "./utils";
import moment, { Moment } from "moment";
import { store } from "..";
import { clerUserSession, refresh } from "./authActions";

import { addAPIcall, completeAPIcall } from "../slices/api";
import { saveRolesUserPerCompany, saveTotalUsersPerCompanyPages, saveUsersPerCompany, } from "store/slices/userCompany";
import { saveProPlanErrorResponse } from "store/slices/planModal";

const groupURL = getBaseUrlForAPI("group");
const resultsReport = getBaseUrlForAPI("resultsReport");

export type IUsageTypes = string | null;
export type IPaymentTypes = string | null;

export interface IManageUserPerCompany {
  email: string;
  emailVerified: boolean;
  name: string;
  role: string;
  username: string;
  password?: string;
}

export interface IUserFilter {
  id?: string;
  status?: string;
  name?: string;
  email?: string;
  role?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const getFilteredUsersPerCompany =
  (params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getFilteredUsersPerCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/user/search`, { params })
          .then(({ data }) => {
            dispatch(saveUsersPerCompany(data.content));
            dispatch(saveTotalUsersPerCompanyPages(data.totalPages));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const getUserPerCompanyRoles =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getUserPerCompanyRoles-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/role`)
          .then(({ data }) => {
            dispatch(saveRolesUserPerCompany(data));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteUsersPerCompany =
  (userId: number, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteUsersPerCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${groupURL}/user/${userId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userDeleted"));
            dispatch(getFilteredUsersPerCompany(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editUserPerCompany =
  (userId: number, user: IManageUserPerCompany, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editUserPerCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${groupURL}/user/${userId}`, user)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userEdited"));
            dispatch(getFilteredUsersPerCompany(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createUserPerCompany =
  (user: IManageUserPerCompany, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createUserPerCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${groupURL}/user`, user)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userCreated"));
            dispatch(getFilteredUsersPerCompany(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const getUsagePerCompany =
  (params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getUsagePerCompany-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/app-usage/search`, { params })
          .then(({ data }) => {
            console.log("🚀 ~ .then ~ data:", data);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const downloadUsageReportById =
  (reference: number) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `downloadUsageReportById-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${resultsReport}/${reference}/xlsx`, { responseType: "blob" })
          .then((response) => {
            let filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(".")[0];

            let url = URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${filename.substring(1)}.xlsx`;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch(async (error) => {
            if (typeof error?.response?.data === "object") {
              let parsedError = JSON.parse(await error?.response?.data.text());
              dispatch(
                saveProPlanErrorResponse({
                  text: getProPlanErrorMessage(
                    parsedError?.errors[0]?.arguments[0]
                  ),
                })
              );
            } else {
              getSnackbarError(error);
            }
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };
      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };
