export interface IAuthPermission {
  disabled: boolean;
  hidden: boolean;
}

export const authRules: { [key: string]: { [key: string]: IAuthPermission } } =
  {
    admin: {
      userManagement: { hidden: false, disabled: false },
      companyManagement: { hidden: true, disabled: true },
      survey: { hidden: false, disabled: false },
      demo: { hidden: false, disabled: false },
      results: { hidden: false, disabled: false },
      passwordManagement: { hidden: false, disabled: false },
      profileManagement: { hidden: false, disabled: false },
    },
    free_plan: {
      userManagement: { hidden: true, disabled: false },
      companyManagement: { hidden: true, disabled: false },
      survey: { hidden: false, disabled: false },
      demo: { hidden: false, disabled: false },
      results: { hidden: false, disabled: false },
      passwordManagement: { hidden: false, disabled: false },
      profileManagement: { hidden: false, disabled: false },
    },
    pro_plan: {
      userManagement: { hidden: true, disabled: false },
      companyManagement: { hidden: true, disabled: false },
      survey: { hidden: false, disabled: false },
      demo: { hidden: false, disabled: false },
      results: { hidden: false, disabled: false },
      passwordManagement: { hidden: false, disabled: false },
      profileManagement: { hidden: false, disabled: false },
    },
    viewer: {
      userManagement: { hidden: true, disabled: false },
      companyManagement: { hidden: true, disabled: false },
      survey: { hidden: true, disabled: false },
      demo: { hidden: true, disabled: false },
      results: { hidden: true, disabled: false },
      passwordManagement: { hidden: true, disabled: false },
      profileManagement: { hidden: true, disabled: false },
    },
    company_admin: {
      userManagement: { hidden: true, disabled: false },
      companyManagement: { hidden: false, disabled: false },
      survey: { hidden: false, disabled: false },
      demo: { hidden: false, disabled: false },
      results: { hidden: false, disabled: false },
      passwordManagement: { hidden: false, disabled: false },
      profileManagement: { hidden: false, disabled: false },
    },
    company_user: {
      userManagement: { hidden: true, disabled: false },
      companyManagement: { hidden: true, disabled: false },
      survey: { hidden: false, disabled: false },
      demo: { hidden: false, disabled: false },
      results: { hidden: false, disabled: false },
      passwordManagement: { hidden: false, disabled: false },
      profileManagement: { hidden: false, disabled: false },
    },
  };
