import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import {
  fetchAvailableModelForCompanyAdmin,
  manageModelsForCompanyAdmin,
} from "store/actions/modelActions";
import { selectModelAssignationsForCompanyAdmin } from "store/slices/model";

import styles from "./index.module.scss";

const ModelsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const modelsAvailablePerCompany = useSelector(
    selectModelAssignationsForCompanyAdmin
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState<number | undefined>();
  const [nameInAssignationModal, setNameInAssignationModal] = useState("");
  const [enabledInAssignationModal, setEnabledInAssignationModal] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    dispatch(fetchAvailableModelForCompanyAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsForModelTable: ITableColumn[] = [
    {
      id: "enabled",
      field: "enabled",
      headerName: `${t("company.enabled")}`,
      minWidth: 100,
      sortable: false,
      valueGetter: (params) =>
        params?.row?.enabled === true
          ? t("company.true")
          : params?.row?.enabled === false
          ? t("company.false")
          : "",
    },
    {
      id: "name",
      field: "name",
      headerName: `${t("company.name")}`,
      minWidth: 350,
      sortable: false,
      valueGetter: (params) => params?.row?.name || "",
    },
  ];

  const modelToEdit = modelsAvailablePerCompany?.find(
    ({ id }) => id === selectedModelId
  );

  const clearForm = () => {
    setNameInAssignationModal("");
    setEnabledInAssignationModal(undefined);
  };

  const handleOnCloseEditAssignationModal = () => {
    setSelectedModelId(undefined);
    clearForm();
    setShowModal(false);
  };

  const handleOnChangeEnabledModel = (event: ChangeEvent<HTMLInputElement>) => {
    setEnabledInAssignationModal(event.target.checked);
  };

  useEffect(() => {
    setNameInAssignationModal(modelToEdit?.name || "");
    setEnabledInAssignationModal(modelToEdit?.enabled || undefined);
  }, [modelToEdit]);

  const handleOnSaveEditAssignationModal = () => {
    const modifiedAssignations = modelsAvailablePerCompany.map((assignation) => {
      if (assignation.id === selectedModelId) {
        return {
          ...assignation,
          enabled: enabledInAssignationModal || null,
          name: nameInAssignationModal || null,
        };
      }
      return assignation;
    });

    dispatch(manageModelsForCompanyAdmin(modifiedAssignations));
    handleOnCloseEditAssignationModal();
  };

  const getEditAssignationModal = () => {
    return (
      <PungoModal
        open={showModal}
        classNames={styles.modalParentForEnableModel}
        childrenClassNames={styles.modalChildrenForEnable}
        title={`${t("model.edit")}`}
        handleClose={handleOnCloseEditAssignationModal}
        primaryAction={{
          label: "OK",
          onClick: handleOnSaveEditAssignationModal,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnCloseEditAssignationModal,
        }}
      >
        <div className={styles.modalContent}>
          <PungoInput
            name={`${t("company.name")}`}
            value={nameInAssignationModal}
            onChange={setNameInAssignationModal}
          />
          <div className={styles.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={enabledInAssignationModal}
                  onChange={handleOnChangeEnabledModel}
                />
              }
              label={t("company.enabled")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  return (
    <div>
      <AdminTable
        columns={columnsForModelTable}
        data={modelsAvailablePerCompany || []}
        handleOnEdit={setShowModal}
        handleOnSelection={setSelectedModelId}
        actions={["edit"]}
      />
      {getEditAssignationModal()}
    </div>
  );
};

export default ModelsTable;
