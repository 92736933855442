import React, {useState} from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface PungoAccordionProps {
  title: string;
  content?: React.ReactNode;
  noborder?: boolean;
  expan?: boolean;
}

const PungoAccordion: React.FC<PungoAccordionProps> = (props) => {
  const { title, content, noborder = false, expan = false } = props;
  const [expanded, setExpanded] = useState (expan);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.mainContainer}>
      <Accordion
        // expanded={expanded === "panel1"}
        expanded={expanded}
        onChange={()=>handleChange()}
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" classes={{ content: styles.innerContainer }}>
          <div className={noborder === false ? styles.titleContainer : styles.titleContainerNoBorder}>
            <div className={styles.accordionTitle}>{title}</div>
            <div className={styles.icon}>{expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.content}>{content}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PungoAccordion;
