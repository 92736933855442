import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import { isEmail } from "components/utils/validations";
import {
  assignModelsToCompany,
  createCompany,
  deleteCompany,
  editCompany,
  fetchCompanies,
  getModelAssignationByCompany,
} from "store/actions/companyActions";
import {
  clearWellModelAssignations,
  selectCompanies,
  selectModelAssignations,
  selectTotalCompaniesPages,
} from "store/slices/company";

import styles from "./index.module.scss";

const AdminCompaniesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const companiesData = useSelector(selectCompanies);
  const totalCompaniesPages = useSelector(selectTotalCompaniesPages);
  const selectedModelsByCompany = useSelector(selectModelAssignations);

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialNameFilter = "";
  const initialContactNameFilter = "";
  const initialContactEmailFilter = "";
  const initialPlanUsageTypeFilter = "all";
  const initialPlanRemainingUsagesFilter = "";
  const initialPlanEndDateFilter = "";
  const initialPlanPaymentTypeFilter = "all";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [contactNameFilter, setContactNameFilter] = useState(
    initialContactNameFilter
  );
  const [contactEmailFilter, setContactEmailFilter] = useState(
    initialContactEmailFilter
  );
  const [planUsageFilter, setPlanUsageFilter] = useState(
    initialPlanUsageTypeFilter
  );
  const [planRemainingUsagesFilter, setPlanRemainingUsagesFilter] = useState(
    initialPlanRemainingUsagesFilter
  );
  const [planEndDateFilter, setPlanEndDateFilter] = useState(
    initialPlanEndDateFilter
  );
  const [planPaymentTypeFilter, setPlanPaymentTypeFilter] = useState(
    initialPlanPaymentTypeFilter
  );

  const [companyNameInModal, setCompanyNameInModal] = useState("");
  const [contactNameInModal, setContactNameInModal] = useState("");
  const [contactMailInModal, setContactMailInModal] = useState("");
  const [notifytMailsInModal, setNotifyMailsInModal] = useState("");
  const [usageTypeInModal, setUsageTypeInModal] = useState("NUMBER_OF_USAGES");
  const [remainingUsagesInModal, setRemainingUsagesInModal] = useState(0);
  const [endPlanDateInModal, setEndPlanDateInModal] = useState("");
  const [paymentTypeInModal, setPaymentTypeInModal] = useState("");

  const [nameInAssignationModal, setNameInAssignationModal] = useState("");
  const [enabledInAssignationModal, setEnabledInAssignationModal] = useState<
    boolean | undefined
  >();
  const [assignedInAssignationModal, setAssignedInAssignationModal] = useState<
    boolean | undefined
  >();

  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const [selectedAssignationId, setSelectedAssignationId] = useState<
    number | undefined
  >();

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [showModelsPerCompanyModal, setShowModelsPerCompanyModal] =
    useState(false);
  const [showEditAssignationModal, setShowEditAssignationModal] =
    useState(false);
  const [filteredCompaniesCallTimeoutId, setFilteredCompaniesCallTimeoutId] =
    useState(0);

  const isNumberUsage = usageTypeInModal === "NUMBER_OF_USAGES";

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const paymentTypesForFiltering = [
    { label: t("admin.filtering.all"), value: "all" },
    { value: "MANUAL", label: "Manual" },
    {
      value: "CREDIT_CARD",
      label: ` 
  ${t("user.creditCard")}`,
    },
  ];

  const usageTypesForFiltering = [
    { label: t("admin.filtering.all"), value: "all" },
    {
      value: "END_TIME",
      label: ` 
  ${t("user.endTime")}`,
    },
    {
      value: "NUMBER_OF_USAGES",
      label: ` 
  ${t("user.usageNumber")}`,
    },
  ];

  const paymentTypes = [
    { value: "MANUAL", label: "Manual" },
    {
      value: "CREDIT_CARD",
      label: ` 
  ${t("user.creditCard")}`,
    },
  ];

  const usageTypes = [
    {
      value: "END_TIME",
      label: ` 
  ${t("user.endTime")}`,
    },
    {
      value: "NUMBER_OF_USAGES",
      label: ` 
  ${t("user.usageNumber")}`,
    },
  ];

  const paramsForCompanyTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(nameFilter && { name: nameFilter }),
    ...(contactNameFilter && { contactName: contactNameFilter }),
    ...(contactEmailFilter && { contactEmail: contactEmailFilter }),
    ...(planUsageFilter !== "all" && { planUsageType: planUsageFilter }),
    ...(planRemainingUsagesFilter && {
      planRemainingUsages: planRemainingUsagesFilter,
    }),
    ...(planEndDateFilter && { planEndDate: planEndDateFilter }),
    ...(planPaymentTypeFilter !== "all" && {
      planPaymentType: planPaymentTypeFilter,
    }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  /**
   * Company filter effects
   */
  useEffect(() => {
    if (!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterCompanies(0);
        allFiltersChange.current = false;
      } else {
        if (filteredCompaniesCallTimeoutId) {
          clearTimeout(filteredCompaniesCallTimeoutId);
        }
        setFilteredCompaniesCallTimeoutId(
          setTimeout(handleFilterCompanies, 800)
        );
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    nameFilter,
    contactNameFilter,
    contactEmailFilter,
    planRemainingUsagesFilter,
    planEndDateFilter,
  ]);

  useEffect(() => {
    handleFilterCompanies(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusFilter,
    planUsageFilter,
    planPaymentTypeFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType,
  ]);

  useEffect(() => {
    if (currentTablePage >= totalCompaniesPages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalCompaniesPages]);

  function handleFilterCompanies(_data: Number): void {
    dispatch(fetchCompanies(paramsForCompanyTable));
  }

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getModelAssignationByCompany(selectedCompanyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModelsPerCompanyModal]);

  const columnsForCompanyTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      minWidth: 180,
      sortable: false,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setIdFilter("");
        },
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "name",
      field: "name",
      headerName: "Name",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.name"),
        type: "text",
        value: nameFilter,
        setter: setNameFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setNameFilter("");
        },
      },
    },
    {
      id: "contactName",
      field: "contactName",
      headerName: "Contact name",
      minWidth: 300,
      sortable: false,
      filter: {
        name: t("admin.filtering.contactName"),
        type: "text",
        value: contactNameFilter,
        setter: setContactNameFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setContactNameFilter("");
        },
      },
    },
    {
      id: "contactEmail",
      field: "contactEmail",
      headerName: "Contact email",
      minWidth: 300,
      sortable: false,
      filter: {
        name: t("admin.filtering.contactEmail"),
        type: "text",
        value: contactEmailFilter,
        setter: setContactEmailFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setContactEmailFilter("");
        },
      },
    },
    {
      id: "planUsageType",
      field: "planUsageType",
      headerName: "Usage type",
      valueGetter: (params) => params?.row?.planDetails?.planUsageType || "",
      minWidth: 250,
      sortable: false,
      filter: {
        name: t("admin.filtering.usageType"),
        type: "list",
        value: planUsageFilter,
        setter: setPlanUsageFilter,
        options: usageTypesForFiltering,
      },
    },
    {
      id: "planRemainingUsages",
      field: "planRemainingUsages",
      headerName: "Remaining usages",
      valueGetter: (params) =>
        params?.row?.planDetails?.planRemainingUsages || "",
      minWidth: 220,
      sortable: false,
      filter: {
        name: t("admin.filtering.remainingUsages"),
        type: "text",
        value: planRemainingUsagesFilter,
        setter: setPlanRemainingUsagesFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setPlanRemainingUsagesFilter("");
        },
      },
    },
    {
      id: "planEndDate",
      field: "planEndDate",
      headerName: "End date",
      valueGetter: (params) => params?.row?.planDetails?.planEndDate || "",
      minWidth: 220,
      sortable: false,
      filter: {
        name: t("admin.filtering.endDate"),
        type: "singleDate",
        value: planEndDateFilter,
        setter: setPlanEndDateFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setPlanEndDateFilter("");
        },
      },
    },
    {
      id: "planPaymentType",
      field: "planPaymentType",
      headerName: "Payment type",
      valueGetter: (params) => params?.row?.planDetails?.planPaymentType || "",
      minWidth: 240,
      sortable: false,
      filter: {
        name: t("admin.filtering.paymentType"),
        type: "list",
        value: planPaymentTypeFilter,
        setter: setPlanPaymentTypeFilter,
        options: paymentTypesForFiltering,
      },
    },
  ];

  const columnsForModelTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      minWidth: 100,
      sortable: false,
    },
    {
      id: "assigned",
      field: "assigned",
      headerName: `${t("company.assigned")}`,
      minWidth: 100,
      sortable: false,
      valueGetter: (params) =>
        params?.row?.assigned === true
          ? t("company.true")
          : params?.row?.assigned === false
          ? t("company.false")
          : "",
    },
    {
      id: "enabled",
      field: "enabled",
      headerName: `${t("company.enabled")}`,
      minWidth: 100,
      sortable: false,
      valueGetter: (params) =>
        params?.row?.enabled === true
          ? t("company.true")
          : params?.row?.enabled === false
          ? t("company.false")
          : "",
    },
    {
      id: "name",
      field: "name",
      headerName: `${t("company.companyModel")}`,
      minWidth: 300,
      sortable: false,
      valueGetter: (params) => params?.row?.name || "",
    },
    {
      id: "wellModelId",
      field: "wellModelId",
      headerName: `${t("company.wellModelId")}`,
      minWidth: 150,
      sortable: false,
      valueGetter: (params) => params?.row?.wellModel?.id || "",
    },
    {
      id: "wellModelName",
      field: "wellModelName",
      headerName: `${t("company.wellModelName")}`,
      minWidth: 200,
      sortable: false,
      valueGetter: (params) => params?.row?.wellModel?.name || "",
    },
  ];

  const companyToEdit = companiesData?.find(
    ({ id }) => id === selectedCompanyId
  );

  const assignationToEdit = selectedModelsByCompany?.find(
    ({ id }) => id === selectedAssignationId
  );

  useEffect(() => {
    setCompanyNameInModal(companyToEdit?.name || "");
    setContactNameInModal(companyToEdit?.contactName || "");
    setContactMailInModal(companyToEdit?.contactEmail || "");
    setNotifyMailsInModal(companyToEdit?.notificationEmails || "");
    setUsageTypeInModal(companyToEdit?.planDetails?.planUsageType || "");
    setRemainingUsagesInModal(
      companyToEdit?.planDetails?.planRemainingUsages || 0
    );
    setEndPlanDateInModal(companyToEdit?.planDetails?.planEndDate || "");
    setPaymentTypeInModal(companyToEdit?.planDetails?.planPaymentType || "");
  }, [companyToEdit]);

  useEffect(() => {
    setNameInAssignationModal(assignationToEdit?.name || "");
    setEnabledInAssignationModal(assignationToEdit?.enabled || undefined);
    setAssignedInAssignationModal(assignationToEdit?.assigned || undefined);
  }, [assignationToEdit]);

  const handleOnDeleteConfirm = () => {
    if (selectedCompanyId) {
      dispatch(deleteCompany(selectedCompanyId, paramsForCompanyTable));
    }
  };

  useEffect(() => {
    if (contactMailInModal.length) {
      setTimeout(() => {
        if (isEmail(contactMailInModal)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [contactMailInModal]);

  const clearForm = () => {
    setCompanyNameInModal("");
    setContactNameInModal("");
    setContactMailInModal("");
    setNotifyMailsInModal("");
    setUsageTypeInModal("");
    setRemainingUsagesInModal(0);
    setEndPlanDateInModal("");
    setPaymentTypeInModal("");
  };

  const clearAssignationForm = () => {
    setNameInAssignationModal("");
    setEnabledInAssignationModal(false);
    setAssignedInAssignationModal(false);
  };

  const handleOnClose = () => {
    setSelectedCompanyId(undefined);
    setShowCreateCompanyModal(false);
    clearForm();
  };

  const handleOnCloseEditAssignationModal = () => {
    setSelectedAssignationId(undefined);
    setShowEditAssignationModal(false);
    clearAssignationForm();
  };

  const handleOnSave = () => {
    const companyPayload = {
      name: companyNameInModal,
      contactName: contactNameInModal,
      contactEmail: contactMailInModal,
      notificationEmails: notifytMailsInModal,
      planDetails: {
        planUsageType: usageTypeInModal,
        planPaymentType: paymentTypeInModal,
        planEndDate: endPlanDateInModal || null,
        planRemainingUsages: remainingUsagesInModal || null,
      },
    };

    if (selectedCompanyId) {
      dispatch(
        editCompany(selectedCompanyId, companyPayload, paramsForCompanyTable)
      );
      clearForm();
    } else {
      dispatch(createCompany(companyPayload, paramsForCompanyTable));
      clearForm();
    }
  };

  const handleOnSaveEditAssignationModal = () => {
    const modifiedAssignations = selectedModelsByCompany.map((assignation) => {
      if (assignation.id === selectedAssignationId) {
        return {
          ...assignation,
          id: selectedAssignationId,
          enabled: enabledInAssignationModal || null,
          assigned: assignedInAssignationModal || null,
          name: nameInAssignationModal || null,
        };
      }
      return assignation;
    });

    if (selectedCompanyId) {
      dispatch(
        assignModelsToCompany(selectedCompanyId, {
          wellAssignations: modifiedAssignations,
        })
      );
    }
    clearAssignationForm();
  };

  const handleOnCloseAssignationModal = () => {
    setShowModelsPerCompanyModal(false);
    setSelectedCompanyId(undefined);
    dispatch(clearWellModelAssignations());
  };

  const isContinueButtonDisabled =
    !companyNameInModal ||
    !contactMailInModal ||
    !contactNameInModal ||
    !usageTypeInModal ||
    !paymentTypeInModal;

  const getCreateCompanyModal = () => {
    return (
      <PungoModal
        open={showCreateCompanyModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedCompanyId ? `${t("company.edit")}` : `${t("company.create")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedCompanyId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: isContinueButtonDisabled,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.grid}>
            <PungoInput
              name={`${t("company.nameLabel")}`}
              value={companyNameInModal}
              onChange={setCompanyNameInModal}
            />
            <PungoInput
              name={`${t("company.paymentType")}`}
              value={paymentTypeInModal || ""}
              onChange={setPaymentTypeInModal}
              type="select"
              options={paymentTypes.map((type) => type)}
            />
            <PungoInput
              name={`${t("company.usageType")}`}
              value={usageTypeInModal || ""}
              onChange={setUsageTypeInModal}
              type="select"
              options={usageTypes.map((type) => type)}
            />
            {isNumberUsage ? (
              <PungoInput
                name={`${t("company.remainingUsages")}`}
                value={remainingUsagesInModal}
                onChange={setRemainingUsagesInModal}
                type="number"
                options={usageTypes.map((type) => type)}
              />
            ) : (
              <PungoInput
                name={`${t("company.usageEndDate")}`}
                value={endPlanDateInModal}
                type="date"
                onChange={(newValue) => setEndPlanDateInModal(newValue)}
              />
            )}
            <PungoInput
              name={`${t("company.contactName")}`}
              value={contactNameInModal}
              onChange={setContactNameInModal}
            />
            <PungoInput
              name={`${t("company.contactMail")}`}
              value={contactMailInModal}
              onChange={setContactMailInModal}
              helperText={
                isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
              }
              showError={!isValidEmail}
            />
          </div>
          <div className={styles.notificationsInput}>
            <PungoInput
              name={`${t("company.notificationMails")}`}
              value={notifytMailsInModal}
              onChange={setNotifyMailsInModal}
              helperText={`${t("company.informativeLabel")}`}
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  const handleOnChangeAssignedModel = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAssignedInAssignationModal(event.target.checked);
  };

  const handleOnChangeEnabledModel = (event: ChangeEvent<HTMLInputElement>) => {
    setEnabledInAssignationModal(event.target.checked);
  };

  const getEditAssignationModal = () => {
    return (
      <PungoModal
        open={showEditAssignationModal}
        classNames={styles.modalParentForCreate}
        childrenClassNames={styles.modalChildrenForCreate}
        title={`${assignationToEdit?.wellModel.id} - ${assignationToEdit?.wellModel?.name}`}
        handleClose={handleOnCloseEditAssignationModal}
        primaryAction={{
          label: selectedCompanyId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSaveEditAssignationModal,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnCloseEditAssignationModal,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.notificationsInput}>
            <PungoInput
              name={`${t("company.companyModel")}`}
              value={nameInAssignationModal}
              onChange={setNameInAssignationModal}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={assignedInAssignationModal}
                  onChange={handleOnChangeAssignedModel}
                />
              }
              label={t("company.assigned")}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={enabledInAssignationModal}
                  onChange={handleOnChangeEnabledModel}
                />
              }
              label={t("company.enabled")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setNameFilter(initialNameFilter);
    setContactNameFilter(initialContactNameFilter);
    setContactEmailFilter(initialContactEmailFilter);
    setPlanUsageFilter(initialPlanUsageTypeFilter);
    setPlanRemainingUsagesFilter(initialPlanRemainingUsagesFilter);
    setPlanEndDateFilter(initialPlanEndDateFilter);
    setPlanPaymentTypeFilter(initialPlanPaymentTypeFilter);
    allFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    nameFilter !== initialNameFilter ||
    contactNameFilter !== initialContactNameFilter ||
    contactEmailFilter !== initialContactEmailFilter ||
    planUsageFilter !== initialPlanUsageTypeFilter ||
    planRemainingUsagesFilter !== initialPlanRemainingUsagesFilter ||
    planEndDateFilter !== initialPlanEndDateFilter ||
    planPaymentTypeFilter !== initialPlanPaymentTypeFilter;

  return (
    <div>
      <AdminTable
        columns={columnsForCompanyTable}
        data={companiesData || []}
        handleOnEdit={setShowCreateCompanyModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedCompanyId}
        handleOnUsage={setShowModelsPerCompanyModal}
        actions={["model", "add", "delete", "edit"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalCompaniesPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateCompanyModal()}
      <PungoModal
        open={showModelsPerCompanyModal}
        title={`${t("model.assignModel")}`}
        handleClose={handleOnCloseAssignationModal}
        classNames={styles.modalParentForUsage}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseAssignationModal,
        }}
      >
        <AdminTable
          columns={columnsForModelTable}
          data={selectedModelsByCompany}
          classNames={styles.usageModal}
          handleOnEdit={setShowEditAssignationModal}
          handleOnSelection={setSelectedAssignationId}
          actions={["edit"]}
        />
      </PungoModal>
      {getEditAssignationModal()}
    </div>
  );
};

export default AdminCompaniesTable;
