import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface IUserData {
  scope: string;
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  session_state: string;
}

export interface IAccountInfo {
  email: string;
  verified: boolean;
  userName: string;
  roles: string[];
  surveyCompleted: boolean;
  loginInfo: ILoginInfo;
}

interface IPlanSuscribe {
  isStandarPlanSubscribed: boolean;
  isProPlanSubscribed: boolean;
  isPremiumPlanSubscribed: boolean;
}

export interface ILoginInfo {
  loginTime: string;
  tokenExpireTime: string;
  sessionExpireTime: string;
}

interface State extends IAccountInfo {
  user: IUserData | undefined;
  subscriptions: IPlanSuscribe;
}

const initialState: State = {
  user: undefined,
  email: "",
  verified: false,
  surveyCompleted: false,
  userName: "",
  roles: [],
  subscriptions: {
    isStandarPlanSubscribed: false,
    isProPlanSubscribed: false,
    isPremiumPlanSubscribed: false,
  },
  loginInfo: {
    loginTime: "",
    tokenExpireTime: "",
    sessionExpireTime: ""
  }
};

const usersSlice = createSlice({
  name: "authData",
  initialState,
  reducers: {
    loginInUser: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
    },
    cleanUser: (state) => {
      state.user = undefined;
    },
    logOutUser: (state) => {
      state.user = undefined;
      state.email = "";
      state.verified = false;
      state.userName = "";
      state.roles = [];
      state.subscriptions.isProPlanSubscribed = false;
      state.subscriptions.isStandarPlanSubscribed = false;
      state.subscriptions.isPremiumPlanSubscribed = false;
      state.loginInfo = {
        loginTime: "",
        tokenExpireTime: "",
        sessionExpireTime: ""
      };
    },
    saveLoginEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    saveUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    saveAccountStatus: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
    },
    saveSurveyStatus: (state, action: PayloadAction<boolean>) => {
      state.surveyCompleted = action.payload;
    },
    saveRole: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    setLoginInfo: (state, action: PayloadAction<ILoginInfo>) => {
      state.loginInfo = action.payload;
    },
    setSubscriptionStandarPlan: (state, action: PayloadAction<boolean>) => {
      state.subscriptions.isStandarPlanSubscribed = action.payload;
    },
    setSubscriptionProPlan: (state, action: PayloadAction<boolean>) => {
      state.subscriptions.isProPlanSubscribed = action.payload;
    },
    setSubscriptionPremiumPlan: (state, action: PayloadAction<boolean>) => {
      state.subscriptions.isPremiumPlanSubscribed = action.payload;
    },
  },
});
export const {
  loginInUser,
  cleanUser,
  logOutUser,
  saveLoginEmail,
  saveUserName,
  saveAccountStatus,
  saveSurveyStatus,
  saveRole,
  setLoginInfo,
  setSubscriptionStandarPlan,
  setSubscriptionProPlan,
  setSubscriptionPremiumPlan,
} = usersSlice.actions;

export default usersSlice.reducer;

export const selectAuthUser = (state: RootState): IUserData | undefined =>
  state.authManagement.user;
export const selectLoginEmail = (state: RootState): string =>
  state.authManagement.email;
export const selectAccountStatus = (state: RootState): boolean =>
  state.authManagement.verified;
export const selectSurveyStatus = (state: RootState): boolean =>
  state.authManagement.surveyCompleted;
export const selectUserName = (state: RootState): string =>
  state.authManagement.userName;
export const selectUserRole = (state: RootState): string[] =>
  state.authManagement.roles;
export const selectLoginInfo = (state: RootState): ILoginInfo =>
  state.authManagement.loginInfo;
export const selectStandarSubscriptionState = (state: RootState): boolean =>
  state.authManagement?.subscriptions?.isStandarPlanSubscribed || false;
export const selectProSubscriptionState = (state: RootState): boolean =>
  state.authManagement?.subscriptions?.isProPlanSubscribed || false;
export const selectPremiumSubscriptionState = (state: RootState): boolean =>
  state.authManagement?.subscriptions?.isPremiumPlanSubscribed || false;

