import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserRole, selectUserName, selectSurveyStatus } from "store/slices/auth";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import DataInputProSection from "components/DemoPage/sections/DataInputProSection";
import ResultsProSection from "components/DemoPage/sections/ResultsProSection";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoAccordion from "pungo-ui/PungoAccordion";
import PungoButton from "pungo-ui/PungoButton";
import PungoInput from "pungo-ui/PungoInput";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import { downloadCalculationTemplate, uploadCalculationTemplate, uploadCalculationTemplateWithModel } from "store/actions/planActions";
import { displaySnackbarAPIError } from "store/actions/utils";
import { selectFetchedProResultsStatus } from "store/slices/plan";
import { fetchAvailableModelList } from "store/actions/modelActions";
import { selectModelList } from "store/slices/model";
import { Download, UploadFile } from "@mui/icons-material";

import styles from "./index.module.scss";

const CalculationBatchPage: React.FC = () => {
  const navigate = useNavigate();
  const userRol = useSelector(selectUserRole)[0];
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectedCalculationModelId, setSelectedCalculationModelId] = useState(0);
  const [renderPage, setRenderPage] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);
  const modelList = useSelector(selectModelList);
  const userRoles = useSelector(selectUserRole);
  const isCompanyUser = userRoles.includes("company_admin") || userRoles.includes("company_user");
  const isAdmin = userRoles.includes("admin");

  useEffect(() => {
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(uploadedFile.name.lastIndexOf(".") + 1);
      if (extension !== "xlsx") {
        dispatch(displaySnackbarAPIError("errorMessage.invalidXLSformat"));
      }
    }
  }, [uploadedFile, dispatch]);

  useEffect(() => {
    setSelectedCalculationModelId(modelList[0]?.id || 0);
  }, [modelList]);

  useEffect(() => {
    if (isCompanyUser || isAdmin) {
      dispatch(fetchAvailableModelList());
    }
  }, [dispatch, isCompanyUser, isAdmin]);

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "pro_plan", "company_user", "company_admin"];

    if (!isLogged) {
      navigate("/login");
    } else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    } else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted]);

  const handleUploadClick = () => {
    if (uploadedFile) {
      onUploadFile();
    } else {
      dispatch(displaySnackbarAPIError("CalculatorBatch.loadData.pending"));
    }
  };

  const onUploadFile = () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("filename", uploadedFile.name);

      if ((isCompanyUser || isAdmin) && selectedCalculationModelId !== 0) {
        dispatch(
          uploadCalculationTemplateWithModel(formData, {
            wellModelId: selectedCalculationModelId,
          })
        );
      } else {
        dispatch(uploadCalculationTemplate(formData));
      }

      setUploadedFile(null);
      clearFileInput(document.getElementById("file"));
    }
  };

  const clearFileInput = (ctrl: any) => {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  };

  const getInstTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.instructionTitle}>{t("CalculatorOnline.instructions.title")} </div>
      <ol>
        <li> {t("CalculatorBatch.instructions.step01")} </li>
        <li> {t("CalculatorBatch.instructions.step02")} </li>
        <li> {t("CalculatorBatch.instructions.step03")} </li>
        <li> {t("CalculatorBatch.instructions.step04")} </li>
      </ol>
    </div>
  );

  const getInstCompanyTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.instructionTitle}>{t("CalculatorOnline.instructions.title")} </div>
      <ol>
        <li> {t("CalculatorBatch.instructionsCompany.step01")} </li>
        <li> {t("CalculatorBatch.instructionsCompany.step02")} </li>
        <li> {t("CalculatorBatch.instructionsCompany.step03")} </li>
        <li> {t("CalculatorBatch.instructionsCompany.step04")} </li>
        <li> {t("CalculatorBatch.instructionsCompany.step05")} </li>
      </ol>
    </div>
  );

  const selectBoxModelElement = () => {
    return (
      <div className={styles.selectBox}>
        <div className={styles.selectTitle}>{t("CalculatorBatch.instructionsCompany.selectTemplate")}</div>
        <div className={styles.selectItems}>
          <PungoInput
            name={""}
            value={selectedCalculationModelId}
            onChange={setSelectedCalculationModelId}
            type="select"
            options={modelList?.map(({ id, name }) => {
              return { value: id, label: name };
            })}
            size="small"
          />
        </div>
      </div>
    );
  };

  const downloadTemplateElement = () => {
    return (
      <PungoButton
        label={`${t("manageProfile.downloadFileButton")}`}
        type="text"
        onClick={() => dispatch(downloadCalculationTemplate())}
        color="secondary"
        icon={<Download />}
        classNames={styles.selectButton}
      />
    );
  };

  const selectFileElement = () => {
    return (
      <input
        type="file"
        id="file"
        onChange={(event: any) => setUploadedFile(event.target.files[0])}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={styles.fileInput}
      />
    );
  };

  const uploadFileElement = () => {
    return (
      <button onClick={handleUploadClick} color="secondary" className={styles.uploadButton}>
        <div className={styles.buttonMessage}>
          <div className={styles.buttonIcon}>
            <UploadFile />
          </div>
          <div className={styles.buttonText}>{`${t("manageProfile.uploadFileButton")}`} </div>
        </div>
      </button>
    );
  };

  const getFileManager = () => (
    <div className={styles.fileManager}>
      {isCompanyUser || isAdmin ? (
        <>
          <div className={styles.elementBlock}>
            <div className={styles.elementRow}>
              <div className={styles.element}>{selectBoxModelElement()}</div>
              <div className={styles.element}>{downloadTemplateElement()}</div>
            </div>
            <div className={styles.elementRow}>
              <div className={styles.element}>{selectFileElement()}</div>
              <div className={styles.element}>{uploadFileElement()}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.elementFlex}>
            <div className={styles.element}>{downloadTemplateElement()}</div>
            <div className={styles.element}>{selectFileElement()}</div>
            <div className={styles.element}>{uploadFileElement()}</div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      {renderPage && (
        <>
          <PungoSectionCard title=" " distribution="block">
            <div className={styles.CalculationDemoBatchPage}>
              <div className={styles.title}>
                {`${t("CalculatorBatch.loadData.title")}`} {isCompanyUser && t("CalculatorBatch.companyPlan.titleDetail")}
                {isAdmin && t("adminLabel")}
              </div>
              <PungoAccordion title={t("PlanSelect.howTo")} content={isCompanyUser ? getInstCompanyTxt() : getInstTxt()} noborder={true} />
              <div className={styles.title2}>{`${t("CalculatorBatch.loadData.fileLoadTitle")}`}</div>
              <div className={styles.loadData}> {getFileManager()}</div>
              <div className={styles.results}>{areProResultsFetched ? <DataInputProSection /> : undefined}</div>
              <div className={styles.results}>{areProResultsFetched ? <ResultsProSection /> : undefined}</div>
            </div>
          </PungoSectionCard>
          <FooterOnePageSection />
          <PungoSelectLanguage />
        </>
      )}
    </>
  );
};

export default CalculationBatchPage;
