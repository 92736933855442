import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import json2mq from "json2mq";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountCircle, Logout, Person, People } from "@mui/icons-material";
import { Divider, ListItemIcon, MenuItem } from "@mui/material";

import PungoMenu from "../../pungo-ui/PungoMenu";
import PungoButton from "../../pungo-ui/PungoButton";
import AstroLogo from "../../assets/AstroLogo.svg";
import PSShortLogo from "../../assets/PungoIcons/ShortIcon.svg";
import PungoClickable from "../../pungo-ui/PungoClickable";
import PungoLoader from "../../pungo-ui/PungoLoader";
import { selectOngoingAPICalls } from "store/slices/api";

import { selectAuthUser, selectUserName, selectUserRole } from "store/slices/auth";
import { useAppDispatch } from "app/hooks";
import { logOutSession } from "store/actions/authActions";
import { isAuthorized } from "../../utils/auth";

import classnames from "classnames";
import breakpoints from "../../styles/export.module.scss";
import colors from "../../styles/export.module.scss";
import styles from "./index.module.scss";

interface NavbarProps {
  type?: "home" | "user" | "plan" | "planSelection";
}
interface INavbarButton {
  section: string;
  label: string;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { type = "user" } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const apiCalls = useSelector(selectOngoingAPICalls);
  const userInfo = useSelector(selectAuthUser);
  const role = useSelector(selectUserRole);
  const isAuth = isAuthorized("userManagement", role[0]);
  const isCompanyAdmin = isAuthorized("companyManagement", role[0]);
  const isLogged = useSelector(selectUserName);

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xl,
    })
  );

  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const homeButtons = [
    {
      section: "benefits",
      label: `${t("sectionLabel.benefits")}`,
    },
    {
      section: "workflow",
      label: `${t("sectionLabel.workflow")}`,
    },
    {
      section: "plans",
      label: `${t("sectionLabel.plans")}`,
    },
    {
      section: "FAQs",
      label: `${t("sectionLabel.FAQs")}`,
    },
    {
      section: "team",
      label: `${t("sectionLabel.team")}`,
    },
    {
      section: "partners",
      label: `${t("sectionLabel.partners")}`,
    },
    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const planButtons = [
    {
      section: "information",
      label: `${t("sectionLabel.information")}`,
    },

    {
      section: "plans",
      label: `${t("sectionLabel.plans")}`,
    },

    {
      section: "FAQs",
      label: `${t("sectionLabel.FAQs")}`,
    },

    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const planSelectionButtons = [
    {
      section: "/",
      label: `${t("sectionLabel.home")}`,
    },
    {
      section: "/plan-select",
      label: `${t("sectionLabel.calculation")}`,
    },
    {
      section: "/plan-info",
      label: `${t("sectionLabel.plans")}`,
    },
    {
      section: "/faq-page",
      label: `${t("sectionLabel.FAQs")}`,
    },
    {
      section: "/contactus-page",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const redirectToMainPage = () => navigate("/");

  const handleOnLogOut = () => {
    if (userInfo) {
      dispatch(logOutSession({ id_token: userInfo.id_token }));
    }
  };

  const getNavbarButtons = (options: INavbarButton[]) =>
    options.map(({ section, label }) => (
      <Link activeClass={styles.activeButton} smooth spy to={section} offset={-120} className={styles.button} key={label}>
        {label}
      </Link>
    ));

  const getNavbarPageLink = (options: INavbarButton[]) =>
    options.map(({ section, label }) => (
      <div
        key={label}
        className={styles.linkToPage}
        onClick={() => {
          if (section !== "/plan-select") window.open(section, "_self");
          if (section === "/plan-select") window.open(section, "_self");
        }}
      >
        {label}
      </div>
    ));

  const handleOnAdmin = () => {
    navigate("/admin");
  };

  const onClickProfile = () => {
    navigate("/admin-profile");
  };

  const contentForMenu = (
    <div>
      <MenuItem onClick={onClickProfile}>
        <ListItemIcon>
          <Person fontSize="small" className={styles.menuItemIcon} />
        </ListItemIcon>
        {t("userLabel")}
      </MenuItem>
      {!isAuth.hidden && (
        <MenuItem onClick={handleOnAdmin}>
          <ListItemIcon>
            <People fontSize="small" className={styles.menuItemIcon} />
          </ListItemIcon>
          {t("adminLabel")}
        </MenuItem>
      )}
      {!isCompanyAdmin.hidden && (
        <MenuItem onClick={() => navigate("/company-admin")}>
          <ListItemIcon>
            <People fontSize="small" className={styles.menuItemIcon} />
          </ListItemIcon>
          {t("adminLabel")}
        </MenuItem>
      )}
      <Divider className={styles.menuItemDivider} />
      <MenuItem onClick={handleOnLogOut}>
        <ListItemIcon>
          <Logout fontSize="small" className={styles.menuItemIcon} />
        </ListItemIcon>
        {t("logOutLabel")}
      </MenuItem>
    </div>
  );

  const getUserAction = () => {
    if (!isLogged) {
      return (
        <PungoButton
          onClick={() => navigate("/login")}
          type="icon"
          icon={
            <AccountCircle
              fontSize="large"
              style={{
                color: `${colors.gray1} `,
              }}
            />
          }
        />
      );
    } else {
      return (
        <PungoMenu
          content={contentForMenu}
          icon={
            <AccountCircle
              fontSize="large"
              style={{
                color: `${colors.blue4}`,
              }}
            />
          }
        />
      );
    }
  };

  const getOptions = (type: string) => {
    switch (type) {
      case "home":
        return getNavbarButtons(homeButtons);
      case "plan":
        return getNavbarButtons(planButtons);
      case "user":
        return undefined;
      case "planSelection":
        return getNavbarPageLink(planSelectionButtons);
      default:
        return getNavbarButtons(homeButtons);
    }
  };

  return (
    <div className={classnames(styles.wrapper, { [styles.single]: !type })}>
      <div className={styles.navbarContainer}>
        {desktopView ? (
          <PungoClickable onClick={redirectToMainPage}>
            <img src={AstroLogo} alt="Pungo logo" className={styles.image} />
          </PungoClickable>
        ) : (
          <PungoClickable onClick={redirectToMainPage}>
            <img src={PSShortLogo} alt="Pungo logo" className={styles.shortImage} />
          </PungoClickable>
        )}
        <div className={styles.space} />
        <div
          className={classnames(styles.actionsContainer, {
            [styles.planPage]: type === "plan",
            [styles.user]: type === "user",
          })}
        >
          {!mobileView ? <PungoMenu options={getOptions(type)} /> : getOptions(type)}
          {getUserAction()}
        </div>
      </div>
      {apiCalls.length > 0 && <PungoLoader />}
    </div>
  );
};

export default Navbar;
