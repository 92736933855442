import React from "react";
import { useNavigate } from "react-router-dom";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoButton from "pungo-ui/PungoButton";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const FooterOnePageSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isSpanishLanguageSelected = i18n.language === "es";

  return (
    <PungoSectionCard title={" "} distribution="block">
      <div className={styles.footerOnePage}>
        <div className={styles.language}>
          <PungoButton label={`${t("language.englishLabel")}`} type="text" onClick={() => changeLanguage("en")} color="primary" disabled={!isSpanishLanguageSelected} />
          <PungoButton label={`${t("language.spanishLabel")}`} type="text" onClick={() => changeLanguage("es")} color="secondary" disabled={isSpanishLanguageSelected} />
        </div>
        <PungoButton label={`${t("politicsAndReferenceTerms")}`} type="text" onClick={() => navigate("/terms")} color="secondary" />
        <div className={styles.copyright}>Copyright © 2023 Pungo</div>
      </div>
    </PungoSectionCard>
  );
};

export default FooterOnePageSection;
