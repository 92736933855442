import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PungoXYGraph from "pungo-ui/PungoXYGraph";

import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";
import SelectableTabs from "../../common/SelectableTabs";
import PungoDemoResultsCard from "../../../../../../pungo-ui/PungoDemoResultsCard/index";

import styles from "./index.module.scss";
import colors from "../../../../../../styles/export.module.scss";

const Saturation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProResults);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const labelsForConsolidatedResultsTable = [
    t("proPlan.tableLabels.saturation.well"),
    t("proPlan.tableLabels.saturation.date"),
    t("proPlan.tableLabels.saturation.saturationHead"),
    t("proPlan.tableLabels.saturation.saturationRiskHead"),
    t("proPlan.tableLabels.saturation.saturationBottom"),
    t("proPlan.tableLabels.saturation.saturationRiskBottom"),
    t("proPlan.tableLabels.saturation.saturationMax"),
  ];

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy alto" : "Very high";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const dataX: number[] = [];
  const dataY: number[] = [];

  proPlanResults[selectedIndex].saturationIndexResult.depth.map((value) => {
    return dataY.push(value);
  });

  proPlanResults[selectedIndex].saturationIndexResult.calciteSaturationIndex.map((value) => {
    return dataX.push(value);
  });

  const xTitle = t("DemoPage.ResultsSection.corrosionSpeedAxis");
  const yTitle = t("DemoPage.ResultsSection.depthAxis");

  const yMax = Math.max(...dataY);
  const nYdiv = Math.floor(yMax / 1000) * 1000 === yMax ? Math.floor(yMax / 1000) : Math.floor(yMax / 1000) + 1;

  const optionsXY = {
    scales: {
      x: {
        responsive: true,
        display: true,
        type: "linear",
        position: "top",
        title: {
          display: true,
          text: xTitle,
          color: "white",
        },
        min: 0,
        // max: xMax,
        // drawTicks: {
        //   display: true,
        //   color: "red",
        // }
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return "white";
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        type: "linear",
        position: "left",
        reverse: true,
        title: {
          display: true,
          text: yTitle,
          color: "white",
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === nYdiv) {
              return "white";
            } else {
              return colors.blue2;
            }
          },
        },
        ticks: {
          stepSize: 1000,
          color: "white",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            return proPlanResults[selectedIndex].wellName;
          },
          label: (ctx: any) => {
            if (proPlanResults[selectedIndex].corrosionResult.corrosion[ctx.dataIndex]) {
              return [
                `${t("DemoPage.ResultsSection.saturationSpeedAxis")} = ${proPlanResults[selectedIndex].saturationIndexResult.calciteSaturationIndex[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.depthAxis")} = ${proPlanResults[selectedIndex].corrosionResult.depth[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.pressionAxis")} = ${proPlanResults[selectedIndex].saturationIndexResult.pressure[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.temperatureAxis")} = ${proPlanResults[selectedIndex].saturationIndexResult.temperature[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.solids")} = ${proPlanResults[selectedIndex].saturationIndexResult.solids[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.incrustationRisk")} = ${translateResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRisk[ctx.dataIndex]) || ""}`,
              ];
            }
          },
        },
      },
    },
  };

  const getTableContent = () => (
    <div className={styles.container}>
      <div className={styles.table}>
        <PungoTable
          values={proPlanResults?.map(({ wellName, saturationIndexResult, date }) => {
            return [
              wellName,
              date,
              saturationIndexResult.saturationIndexHead % 1 !== 0 ? saturationIndexResult.saturationIndexHead.toFixed(2) : saturationIndexResult.saturationIndexHead,
              translateResults(saturationIndexResult.encrustationRiskHead),
              saturationIndexResult.saturationIndexDepth % 1 !== 0 ? saturationIndexResult.saturationIndexDepth.toFixed(2) : saturationIndexResult.saturationIndexDepth,
              translateResults(saturationIndexResult.encrustationRiskDepth),
              saturationIndexResult.saturationIndexMax % 1 !== 0 ? saturationIndexResult.saturationIndexMax.toFixed(2) : saturationIndexResult.saturationIndexMax,
            ];
          })}
          labels={labelsForConsolidatedResultsTable}
          height={300}
        />
      </div>
      <div className={styles.tabs}>
        <SelectableTabs labels={proPlanResults?.map(({ wellName }) => wellName)} onSelect={setSelectedIndex} selectedIndex={selectedIndex} />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.saturationRate")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headSaturation") as string}
            result={
              proPlanResults[selectedIndex].saturationIndexResult.saturationIndexHead % 1 !== 0
                ? proPlanResults[selectedIndex].saturationIndexResult.saturationIndexHead.toFixed(2)
                : proPlanResults[selectedIndex].saturationIndexResult.saturationIndexHead
            }
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomSaturation") as string}
            result={
              proPlanResults[selectedIndex].saturationIndexResult.saturationIndexDepth % 1 !== 0
                ? proPlanResults[selectedIndex].saturationIndexResult.saturationIndexDepth.toFixed(2)
                : proPlanResults[selectedIndex].saturationIndexResult.saturationIndexDepth
            }
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxSaturation") as string}
            result={
              proPlanResults[selectedIndex].saturationIndexResult.saturationIndexMax % 1 !== 0
                ? proPlanResults[selectedIndex].saturationIndexResult.saturationIndexMax.toFixed(2)
                : proPlanResults[selectedIndex].saturationIndexResult.saturationIndexMax
            }
            color={colors.blue4}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.incrustationRisk")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskHead)}
            color={getColorForResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskHead)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskDepth)}
            color={getColorForResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskDepth)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskMax)}
            color={getColorForResults(proPlanResults[selectedIndex].saturationIndexResult.encrustationRiskMax)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.saturationProfile")} </div>
        <div className={styles.xyGraphContainer}>
          <PungoXYGraph dataX={dataX} dataY={dataY} options={optionsXY} />
        </div>
      </div>
    </div>
  );

  return <PungoDemoAccordion title={t("DemoPage.ResultsSection.saturationRate")} content={getTableContent()} />;
};

export default Saturation;
