import { ISnackbarState, showSnackbar } from "../slices/snackbar";
import { t } from "i18next";
import { store } from "../../store";
import { logOutSession } from "./authActions";

const { dispatch } = store;

const getErrorMessage = (errorCode: string, type?: string): string => {
  switch (errorCode) {
    case "Repeated":
      return "errorMessage.repeated";
    case "UserEmailAlreadyVerified":
      return "errorMessage.alreadyVerified";
    case "ForgotPasswordCodeAlreadyUsed":
      return "errorMessage.codeAlreadyUsed";
    case "NotVerifiedEmail":
      return "errorMessage.notVerifiedEmail";
    case "GenericMessage":
      return "errorMessage.genericMessage";
    case "InvalidCredentials":
      return "errorMessage.login";
    case "URL":
      return "errorMessage.invalidURL";
    case "InvalidField":
      switch (type) {
        case "username":
          return "errorMessage.forgotPasswordIncorrectMail";
        default:
          return "errorMessage.invalidFields";
      }
    case "expiredSession":
      return "sessionLostLabel";
    default:
      return errorCode;
  }
};

export const getProPlanErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "no planRemainingUsages":
      return "errorMessage.noPlanRemainingUsages";
    case "planRemainingUsages":
      return "errorMessage.planRemainingUsages";
    case "planEndDate has already passed":
      return "errorMessage.planEndDateHasAlreadyPassed";
    case "planEndDate":
      return "errorMessage.planEndDate";
    case "invalid planUsageType":
      return "errorMessage.invalidPlanUsageType";
    default:
      return errorCode;
  }
};

interface ISnackbarAction {
  payload: ISnackbarState;
  type: string;
}

export const displaySnackbarAPIError = (
  errorCode: string,
  field?: string
): ISnackbarAction =>
  showSnackbar({
    type: "error",
    text: t(getErrorMessage(errorCode, field)),
  });

export const displaySnackbarAPISuccess = (
  successMessage: string
): ISnackbarAction =>
  showSnackbar({
    type: "success",
    text: t(successMessage),
  });

export const getSnackbarError = (error: any) => {
  const idToken = store.getState().authManagement.user?.id_token;
  const errorCode = error?.response?.status;
  const errormessage = error?.code;
  const isNetworkError = `${errormessage}` === "ERR_NETWORK";

  if (isNetworkError) {
    dispatch(displaySnackbarAPIError("errorMessage.noNetwork"));
  } else if (error.response.data) {
    const { errorMessage, fields } = error.response?.data?.errors[0];
    dispatch(displaySnackbarAPIError(errorMessage, fields[0]));
  } else if (
    !error.response.data &&
    errorCode === 401 &&
    `${errormessage}` === "ERR_BAD_REQUEST"
  ) {
    if (idToken) {
      dispatch(logOutSession({ id_token: idToken }));
    }
  } else {
    dispatch(displaySnackbarAPIError("GenericMessage"));
  }
};
