import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface IUsageUserDetails {
  id: number;
  name: string;
  email: string;
}

export interface IPlanDetailsPerCompany {
  planUsageType: string;
  planRemainingUsages: number | null;
  planEndDate: string | null;
  planPaymentType: string;
}

export interface ICompany {
  id: number;
  status: string;
  name: string;
  contactName: string;
  contactEmail: string;
  notificationEmails: string;
  planDetails: IPlanDetailsPerCompany;
}

export interface ICompanyForSelect {
  id: number;
  name: string;
}

export interface ICompany {
  id: number;
  status: string;
  name: string;
  contactName: string;
  contactEmail: string;
  notificationEmails: string;
  planDetails: IPlanDetailsPerCompany;
}

export interface IWellModel {
  id: number;
  name: string;
}

export interface IModelAssignation {
  id: number;
  assigned: boolean;
  enabled: boolean;
  name: string;
  wellModel: IWellModel;
}

export interface IUsage {
  id: number;
  time: string;
  user: IUsageUserDetails;
  wellModel: IWellModel;
}

const emptyWellModel: IWellModel = {
  id: 0,
  name: "",
};

const emptyUsageUserDetail: IUsageUserDetails = {
  id: 0,
  name: "",
  email: "",
};

const emptyUsage: IUsage = {
  id: 0,
  time: "",
  user: emptyUsageUserDetail,
  wellModel: emptyWellModel,
};

const emptyModelAssignation: IModelAssignation = {
  id: 0,
  assigned: false,
  enabled: false,
  name: "",
  wellModel: emptyWellModel,
};

const emptyPlanDetailsPerCompany: IPlanDetailsPerCompany = {
  planUsageType: "",
  planRemainingUsages: 0,
  planEndDate: "",
  planPaymentType: "",
};

const emptyCompany: ICompany = {
  id: 0,
  status: "",
  name: "",
  contactName: "",
  contactEmail: "",
  notificationEmails: "",
  planDetails: emptyPlanDetailsPerCompany,
};

const emptyCompanyForSelect: ICompanyForSelect = {
  id: 0,
  name: ""
};

const initialState = {
  companies: [emptyCompany],
  companiesForSelect: [emptyCompanyForSelect],
  totalCompaniesPages: 0,
  totalUsagePages: 0,
  wellModelAssignations: [emptyModelAssignation],
  usageDetails: [emptyUsage],
};

const companySlice = createSlice({
  name: "companiesData",
  initialState,
  reducers: {
    clearCompanies: (state) => {
      state.companies = [];
      state.totalCompaniesPages = 0;
      state.totalUsagePages = 0;
      state.wellModelAssignations = [];
      state.usageDetails = [];
      state.companiesForSelect = [];
    },
    saveCompanies: (state, action: PayloadAction<ICompany[]>) => {
      state.companies = action.payload;
    },
    saveCompaniesList: (state, action: PayloadAction<ICompanyForSelect[]>) => {
      state.companiesForSelect = action.payload;
    },
    saveUsages: (state, action: PayloadAction<IUsage[]>) => {
      state.usageDetails = action.payload;
    },
    saveTotalCompaniesPages: (state, action: PayloadAction<number>) => {
      state.totalCompaniesPages = action.payload;
    },
    saveTotalUsagePages: (state, action: PayloadAction<number>) => {
      state.totalUsagePages = action.payload;
    },
    saveWellModelAssignations: (
      state,
      action: PayloadAction<IModelAssignation[]>
    ) => {
      state.wellModelAssignations = action.payload;
    },
    clearWellModelAssignations: (state) => {
      state.wellModelAssignations = [];
    },
  },
});
export const {
  saveCompanies,
  saveCompaniesList,
  clearCompanies,
  saveTotalCompaniesPages,
  saveWellModelAssignations,
  saveTotalUsagePages,
  saveUsages,
  clearWellModelAssignations,
} = companySlice.actions;

export default companySlice.reducer;

export const selectCompanies = (state: RootState): ICompany[] =>
  state.companyManagement.companies;

export const selectCompaniesForSelect = (state: RootState): ICompanyForSelect[] =>
  state.companyManagement.companiesForSelect;

export const selectUsages = (state: RootState): IUsage[] =>
  state.companyManagement.usageDetails;

export const selectTotalCompaniesPages = (state: RootState): number =>
  state.companyManagement.totalCompaniesPages;

export const selectTotalUsagePages = (state: RootState): number =>
  state.companyManagement.totalUsagePages;

export const selectModelAssignations = (
  state: RootState
): IModelAssignation[] => state.companyManagement.wellModelAssignations;
