import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PungoSectionCard from "../../../pungo-ui/PungoSectionCard";
import styles from "./index.module.scss";
import PungoAccordion from "../../../pungo-ui/PungoAccordion";

const FAQSection: React.FC = () => {
  const { t } = useTranslation();

  const questionFAQ01 = () => (
    <div className={styles.text}>
      <ul>
        <li>
          <Trans>
            {t("FAQSection.FAQ01.i01")}
          </Trans>
        </li>
        <li>
          <Trans>
            {t("FAQSection.FAQ01.i02")}
          </Trans>
        </li>
        <li>
          <Trans>
            {t("FAQSection.FAQ01.i03")}
          </Trans>
        </li>
      </ul>
    </div>
  );

  const questionFAQ02 = () => (
    <div className={styles.text}>
      <p> <Trans>{t("FAQSection.FAQ02.p1")}</Trans></p>
    </div>
  );

  const questionFAQ03 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ03.i01")}
      </Trans>
    </div>
  );

  const questionFAQ04 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ04.i01")}
      </Trans>
    </div>
  );

  const questionFAQ06 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ06.i01")}
      </Trans>
    </div>
  );

  const questionFAQ07 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ07.i01")}
      </Trans>
    </div>
  );

  const questionFAQ08 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ08.i01")}
      </Trans>
    </div>
  );

  const questionFAQ09 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ09.i01")}
      </Trans>
    </div>
  );

  const questionFAQ10 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ10.i01")}
      </Trans>
    </div>
  );

  const questionFAQ11 = () => (
    <div className={styles.text}>
      <Trans>
        {t("FAQSection.FAQ11.i01")}
      </Trans>
    </div>
  );

  return (
    <PungoSectionCard
      title={t("FAQSection.mainTitle")}
      id="FAQs"
    >
      <div className={styles.containerFAQSection}>
        <PungoAccordion
          title={t("FAQSection.FAQ01.title")}
          content={questionFAQ01()}
        />
          <PungoAccordion
            title={t("FAQSection.FAQ02.title")}
            content={questionFAQ02()}
          />
        <PungoAccordion
          title={t("FAQSection.FAQ06.title")}
          content={questionFAQ06()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ03.title")}
          content={questionFAQ03()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ04.title")}
          content={questionFAQ04()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ07.title")}
          content={questionFAQ07()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ08.title")}
          content={questionFAQ08()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ09.title")}
          content={questionFAQ09()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ10.title")}
          content={questionFAQ10()}
        />
        <PungoAccordion
          title={t("FAQSection.FAQ11.title")}
          content={questionFAQ11()}
        />
      </div>
    </PungoSectionCard>
  );
};

export default FAQSection;