import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";

import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { store } from "..";
import { clerUserSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import {
  IModelAssignationCompanyAdmin,
  saveModelAssignationsForCompanyAdmin,
  saveModelList,
  saveModels,
  saveTotalModelsPages,
} from "store/slices/model";

const modelURL = getBaseUrlForAPI("model");
const calculationPro = getBaseUrlForAPI("calculationPro");
const groupURL = getBaseUrlForAPI("group");

export interface IModelParams {
  id?: string;
  status?: string;
  name?: string;
  description?: string;
  url?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export interface ICreateModel {
  name: string;
  description: string;
  url: string;
}

export const fetchModels =
  (params?: IModelParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchModels-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${modelURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveModels(data.content));
            dispatch(saveTotalModelsPages(data.totalPages));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createModel =
  (model: ICreateModel, params: IModelParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createQuestion-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${modelURL}`, model)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelCreated"));
            dispatch(fetchModels(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteModel =
  (modelId: number, params: IModelParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteModel-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${modelURL}/${modelId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelDeleted"));
            dispatch(fetchModels(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editModel =
  (modelId: number, model: ICreateModel, params: IModelParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editModel-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${modelURL}/${modelId}`, model)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelEdited"));
            dispatch(fetchModels(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchModelsByCompany =
  (params?: IModelParams) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchModels-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${modelURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveModels(data.content));
            dispatch(saveTotalModelsPages(data.totalPages));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchAvailableModelList =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchAvailableModelList-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${calculationPro}/well/model/option-list`)
          .then(({ data }) => {
            dispatch(saveModelList(data));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchAvailableModelForCompanyAdmin =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchAvailableModelForCompanyAdmin-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${groupURL}/company/assignModel`)
          .then(({ data }) => {
            dispatch(saveModelAssignationsForCompanyAdmin(data.wellAssignations));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const manageModelsForCompanyAdmin =
  (enabledModels: IModelAssignationCompanyAdmin[]) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createQuestion-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${groupURL}/company/assignModel`, {
            wellAssignations: enabledModels,
          })
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.modelCreated"));
            dispatch(fetchAvailableModelForCompanyAdmin());
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };