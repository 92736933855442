import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface ISnackbarState {
  text: string;
  type: "error" | "success";
}

const initialState: ISnackbarState = {
  text: "",
  type: "success",
};
const snackbarSlice = createSlice({
  name: "snackbarData",
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<ISnackbarState>) => {
      state.text = action.payload.text;
      state.type = action.payload.type;
    },
    clearSnackbar: (state) => {
      state.text = "";
    },
  },
});
export const { showSnackbar, clearSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;

export const selectSnackbar = (state: RootState): ISnackbarState =>
  state.snackbarManagement;
