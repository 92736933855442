export const free_plan = "free_plan";
export const pro_plan = "pro_plan";
export const plans = new Set<any>([free_plan, pro_plan]);
export const paidPlans = new Set<any>([pro_plan]);

export const isPlan = (value: string): boolean => {
  return plans.has({ name: value });
};

export const isPaidPlan = (value: string): boolean => {
  return paidPlans.has(value);
};