import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface ICalcModel {
  id: number;
  status: string;
  name: string;
  description: string;
  url: string;
}

export interface IWellModel {
  id: number;
  name: string;
}

export interface IModelAssignation {
  id: number;
  assigned: boolean;
  enabled: boolean;
  name: string;
  wellModel: IWellModel;
}

export interface IModelAssignationCompanyAdmin {
  id: number;
  enabled: boolean | null;
  name: string | null;
}

const emptyCalcModel: ICalcModel = {
  id: 0,
  status: "",
  name: "",
  description: "",
  url: "",
};

const emptyWellModel: IWellModel = {
  id: 0,
  name: "",
};

const emptyModelAssignation: IModelAssignation = {
  id: 0,
  assigned: false,
  enabled: false,
  name: "",
  wellModel: emptyWellModel,
};

const emptyModelAssignationCompanyAdmin: IModelAssignationCompanyAdmin = {
  id: 0,
  enabled: false,
  name: "",
};

const initialState = {
  models: [emptyCalcModel],
  totalModelsPages: 0,
  wellAssignations: [emptyModelAssignation],
  modelAssignationsCompanyAdmin: [emptyModelAssignationCompanyAdmin],
  wellModelList: [emptyWellModel],
};

const modelSlice = createSlice({
  name: "calcModelData",
  initialState,
  reducers: {
    clearModels: (state) => {
      state.models = [];
      state.wellModelList = [];
      state.modelAssignationsCompanyAdmin = [];
      state.totalModelsPages = 0;
    },
    saveModels: (state, action: PayloadAction<ICalcModel[]>) => {
      state.models = action.payload;
    },
    saveModelList: (state, action: PayloadAction<IWellModel[]>) => {
      state.wellModelList = action.payload;
    },
    saveModelAssignationsForCompanyAdmin: (
      state,
      action: PayloadAction<IModelAssignationCompanyAdmin[]>
    ) => {
      state.modelAssignationsCompanyAdmin = action.payload;
    },
    saveTotalModelsPages: (state, action: PayloadAction<number>) => {
      state.totalModelsPages = action.payload;
    },
  },
});
export const {
  saveModels,
  clearModels,
  saveTotalModelsPages,
  saveModelList,
  saveModelAssignationsForCompanyAdmin,
} = modelSlice.actions;

export default modelSlice.reducer;

export const selectModels = (state: RootState): ICalcModel[] =>
  state.modelManagement.models;

export const selectModelList = (state: RootState): IWellModel[] =>
  state.modelManagement.wellModelList;

export const selectModelAssignationsForCompanyAdmin = (
  state: RootState
): IModelAssignationCompanyAdmin[] =>
  state.modelManagement.modelAssignationsCompanyAdmin;

export const selectTotalModelsPages = (state: RootState): number =>
  state.modelManagement.totalModelsPages;
