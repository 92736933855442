import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { IRole } from "./user";

interface IUserPerCompany {
  id: number;
  status: string;
  username: string;
  name: string;
  email: string;
  emailVerified: boolean;
  role: string;
}

const emptyUser: IUserPerCompany = {
  id: 0,
  status: "",
  username: "",
  name: "",
  email: "",
  emailVerified: false,
  role: "",
};

const emptyRole: IRole = {
  name: "",
  representation: "",
  type: "",
};

const initialState = {
  usersPerCompany: [emptyUser],
  roles: [emptyRole],
  totalUsersPerCompanyPages: 0,
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    clearUserPerCompanyManagement: (state) => {
      state.usersPerCompany = [];
      state.roles = [];
      state.totalUsersPerCompanyPages = 0;
    },
    saveUsersPerCompany: (state, action: PayloadAction<IUserPerCompany[]>) => {
      state.usersPerCompany = action.payload;
    },
    saveRolesUserPerCompany: (state, action: PayloadAction<IRole[]>) => {
      state.roles = action.payload;
    },
    saveTotalUsersPerCompanyPages: (state, action: PayloadAction<number>) => {
      state.totalUsersPerCompanyPages = action.payload;
    },

  },
});
export const {
  saveUsersPerCompany,
  saveRolesUserPerCompany,
  clearUserPerCompanyManagement,
  saveTotalUsersPerCompanyPages,
} = userSlice.actions;

export default userSlice.reducer;

export const selectAllUsersPerCompany = (state: RootState): IUserPerCompany[] =>
  state.companyUserManagement.usersPerCompany;

export const selectAllRolesUserPerCompany = (state: RootState): IRole[] =>
  state.companyUserManagement.roles;

export const selectTotalUsersPerCompanyPages = (state: RootState): number =>
  state.companyUserManagement.totalUsersPerCompanyPages;