// ****************************************************************************************
//  Astro Component
//  Plan by transaction pricing
//
//  Based on pungo components PungoPlanByTransactionGroup and PungoPlanByTransactionCard
// ****************************************************************************************

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoPlanByTransactionGroup from "pungo-ui/PungoPlanByTransactionGroup";
import classNames from "classnames";
import styles from "./index.module.scss";

interface PungoPlanByTransactionCardProps {
  id: string;
  name: string;
  description: string;
  cost: string;
  commentToCost: string;
  frequency: string;
  link: string;
  benefits: string[];
}

const PlanByTransactionSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  
  const language = i18n.language;
  const navigate = useNavigate();
  const [plan, setPlan] = useState("monthly");

  const handleGoToDemo = (e: any) => {
    if (e.id !== "Plan4") {
      navigate("signup");
    } else {
      window.location.href = "#contactUs";
    }
  };

  const dataForPlans: PungoPlanByTransactionCardProps[] = [
    {
      id: "Plan2",
      name: `${t("planByTransaction.Plan2.name")}`,
      description: `${t("planByTransaction.Plan2.description")}`,
      cost: plan === "monthly" ? "199" : "179",
      commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan2.commentToCost.byMonth")}` : `${t("planByTransaction.Plan2.commentToCost.byYear")}`,
      frequency: plan === "monthly" ? `${t("planByTransaction.Plan2.frequency.byMonth")}` : `${t("planByTransaction.Plan2.frequency.byYear")}`,
      link: `${t("planByTransaction.Plan2.link")}`,
      benefits: [`${t("planByTransaction.Plan2.benefits.b0")}`, `${t("planByTransaction.Plan2.benefits.b1")}`],
    },
    {
      id: "Plan3",
      name: `${t("planByTransaction.Plan3.name")}`,
      description: `${t("planByTransaction.Plan3.description")}`,
      cost: plan === "monthly" ? "999" : "899",
      commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan3.commentToCost.byMonth")}` : `${t("planByTransaction.Plan3.commentToCost.byYear")}`,
      frequency: plan === "monthly" ? `${t("planByTransaction.Plan3.frequency.byMonth")}` : `${t("planByTransaction.Plan3.frequency.byYear")}`,
      link: `${t("planByTransaction.Plan3.link")}`,
      benefits: [`${t("planByTransaction.Plan3.benefits.b0")}`, `${t("planByTransaction.Plan3.benefits.b1")}`],
    },
    {
      id: "Plan4",
      name: `${t("planByTransaction.Plan4.name")}`,
      description: `${t("planByTransaction.Plan4.description")}`,
      cost: "",
      commentToCost: " ",
      frequency: " ",
      link: `${t("planByTransaction.Plan4.link")}`,
      benefits: [`${t("planByTransaction.Plan4.benefits.b0")}`],
    },
  ];

  const handlePlanChange = (newPlan: string) => {
    setPlan(newPlan);
  };

  useEffect(() => {
    // eslint Does not recognize use of dataForPlans outside useEffect
    // eslint-disable-next-line
    const dataForPlans: PungoPlanByTransactionCardProps[] = [
      {
        id: "Plan2",
        name: `${t("planByTransaction.Plan2.name")}`,
        description: `${t("planByTransaction.Plan2.description")}`,
        cost: plan === "monthly" ? "199" : "179",
        commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan2.commentToCost.byMonth")}` : `${t("planByTransaction.Plan2.commentToCost.byYear")}`,
        frequency: plan === "monthly" ? `${t("planByTransaction.Plan2.frequency.byMonth")}` : `${t("planByTransaction.Plan2.frequency.byYear")}`,
        link: `${t("planByTransaction.Plan2.link")}`,
        benefits: [`${t("planByTransaction.Plan2.benefits.b0")}`, `${t("planByTransaction.Plan2.benefits.b1")}`],
      },
      {
        id: "Plan3",
        name: `${t("planByTransaction.Plan3.name")}`,
        description: `${t("planByTransaction.Plan3.description")}`,
        cost: plan === "monthly" ? "999" : "899",
        commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan3.commentToCost.byMonth")}` : `${t("planByTransaction.Plan3.commentToCost.byYear")}`,
        frequency: plan === "monthly" ? `${t("planByTransaction.Plan3.frequency.byMonth")}` : `${t("planByTransaction.Plan3.frequency.byYear")}`,
        link: `${t("planByTransaction.Plan3.link")}`,
        benefits: [`${t("planByTransaction.Plan3.benefits.b0")}`, `${t("planByTransaction.Plan3.benefits.b1")}`],
      },
      {
        id: "Plan4",
        name: `${t("planByTransaction.Plan4.name")}`,
        description: `${t("planByTransaction.Plan4.description")}`,
        cost: "",
        commentToCost: " ",
        frequency: " ",
        link: `${t("planByTransaction.Plan4.link")}`,
        benefits: [`${t("planByTransaction.Plan4.benefits.b0")}`],
      },
    ];
  }, [language, plan, t]);

  return (
    <>
      {/* =======================================
            billing period selector 
            ========================================*/}
      <PungoSectionCard title={`${t("planByTransaction.title")}`} description={`${t("planByTransaction.description")}`} id="plans" distribution="block">
        <div className={styles.planSwitcher}>
          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "monthly",
            })}
            onClick={() => handlePlanChange("monthly")}
          >
            {`${t("planByTransaction.monthly")}`}
          </button>
          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "annual",
            })}
            onClick={() => handlePlanChange("annual")}
          >
            {`${t("planByTransaction.annual")}`}
          </button>
        </div>

        {/* ====================================================
            Call to price by transcation component with data
            =================================================*/}
        <div className={styles.container}>
          <PungoPlanByTransactionGroup data={dataForPlans} handleClick={handleGoToDemo} />
        </div>
      </PungoSectionCard>
    </>
  );
};

export default PlanByTransactionSection;
