import { AppDispatch, store } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";

import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { clerUserSession, refresh } from "./authActions";
import {
  saveRoles,
  saveTotalUsersPages,
  saveUsage,
  saveUsageById,
  saveUsageByUserId,
  saveUsageResumeTotalPages,
  saveUsageTotalUsersPages,
  saveUserDetailById,
  saveUsers,
} from "../slices/user";
import { addAPIcall, completeAPIcall } from "../slices/api";

const userURL = getBaseUrlForAPI("user");
const apiURL = getBaseUrlForAPI("api");
const appUsageURL = getBaseUrlForAPI("appUsage");

export type IUsageTypes = string | null;
export type IPaymentTypes = string | null;

export interface IManageUser {
  unverifiedCompanyName?: string;
  company?: {
    name: string;
    id: number;
  };
  email: string;
  emailVerificationCode?: string;
  passwordChangeCode?: string;
  emailVerified: boolean;
  name: string;
  role: string;
  surveyCompleted: boolean;
  username: string;
  password?: string;
  planDetails?: {
    planUsageType: IUsageTypes;
    planRemainingUsages: number;
    planEndDate: string;
    planPaymentType: IPaymentTypes;
  };
}

export interface IUserFilter {
  id?: string;
  status?: string;
  name?: string;
  companyName?: string;
  email?: string;
  role?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export interface IUsageFilter {
  time?: string;
  id?: string;
  user_id?: string;
  user_email?: string;
  dtype?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const getFilteredUsers =
  (params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getUsers-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${userURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveUsers(data.content));
            dispatch(saveTotalUsersPages(data.totalPages));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const getRoles =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `getRoles-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${apiURL}/core/role`)
          .then(({ data }) => {
            dispatch(saveRoles(data));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const deleteUsers =
  (userId: number, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `deleteUsers-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .delete(`${userURL}/${userId}`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userDeleted"));
            dispatch(getFilteredUsers(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const editUser =
  (userId: number, user: IManageUser, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `editUser-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .put(`${userURL}/${userId}`, user)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userEdited"));
            dispatch(getFilteredUsers(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const createUser =
  (user: IManageUser, params: IUserFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `createUser-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${userURL}`, user)
          .then(() => {
            dispatch(displaySnackbarAPISuccess("successMessage.userCreated"));
            dispatch(getFilteredUsers(params));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchUsageResume =
  (params: IUsageFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchUsageResume-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${appUsageURL}/search`, { params })
          .then(({ data }) => {
            dispatch(saveUsage(data.content));
            dispatch(saveUsageResumeTotalPages(data.totalPages));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchUsageByUserId =
  (userId: number, params?: IUsageFilter) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchUsageByUserId-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${userURL}/${userId}/app-usages`, { params })
          .then(({ data }) => {
            dispatch(saveUsageByUserId(data.content));
            dispatch(saveUsageTotalUsersPages(data.totalPages));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchUsageById =
  (userId: number) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchUsageById-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${appUsageURL}/${userId}`)
          .then(({ data }) => {
            dispatch(saveUsageById(data));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchUserDetailsById =
  (userId: number) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchUserDetailsById-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${userURL}/${userId}`)
          .then(({ data }) => {
            dispatch(saveUserDetailById(data));
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };
