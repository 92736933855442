import React from "react";

import styles from "./index.module.scss";

interface PungoPlanByTransactionCardProps {
  id: string;
  name: string;
  description: string;
  cost: string;
  commentToCost: string;
  frequency: string;
  link: string;
  benefits: string[];
  handleClick: any;
}

const PungoPlanByTransactionCard: React.FC<PungoPlanByTransactionCardProps> = (props) => {
  const { id, name, description, cost, commentToCost, frequency, link, benefits, handleClick } = props;

  const getBenefits = (data: any) => {
    return data.map((txt: any, index: any) => {
      return <li key={index}>{data[index]}</li>;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.cost}>$ {cost}</div>
      {/* <div className={styles.commentToCost}>{commentToCost}</div> */}
      <div className={styles.frequency}>{frequency} </div>
      <button className={styles.link} onClick={() => handleClick({id})}>{link}</button>
      <div className={styles.benefits}>
        <ul>{getBenefits(benefits)}</ul>
      </div>
    </div>
  );
};

export default PungoPlanByTransactionCard;
