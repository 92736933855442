import React from "react";

import Modal from "@mui/material/Modal";
import PungoButton from "../PungoButton";

import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoModalProps {
  open: boolean;
  title?: string;
  handleClose: (state: boolean) => void;
  children?: React.ReactNode;
  primaryAction: { label: string; onClick?: () => void; disabled?: boolean };
  secondaryAction?: { label: string; onClick?: () => void; disabled?: boolean };
  classNames?: string;
  childrenClassNames?: string;
}

const PungoModal: React.FC<PungoModalProps> = (props) => {
  const {
    open,
    handleClose,
    children,
    title,
    primaryAction,
    secondaryAction,
    classNames,
    childrenClassNames,
  } = props;

  const handleOnClose = () => handleClose(false);

  const getPrimaryAction = () => {
    if (primaryAction?.onClick) {
      primaryAction.onClick();
      handleOnClose();
    } else handleOnClose();
  };
  const getSecondaryAction = () => {
    if (secondaryAction?.onClick) {
      secondaryAction.onClick();
      handleOnClose();
    } else handleOnClose();
  };

  return (
    <div className={styles.container}>
      <Modal open={open} onClose={handleOnClose}>
        <div className={classnames(styles.modal, classNames)}>
          {title && <div className={styles.header}>{title}</div>}
          <div className={classnames(styles.children, childrenClassNames)}>{children}</div>
          <div className={styles.actionSection}>
            {secondaryAction && (
              <PungoButton
                onClick={getSecondaryAction}
                label={secondaryAction.label}
                type="outlined"
                disabled={secondaryAction.disabled}
              />
            )}
            <div className={styles.primaryAction}>
              <PungoButton
                onClick={getPrimaryAction}
                label={primaryAction.label}
                disabled={primaryAction.disabled}
                color="secondary"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PungoModal;
